import React, { useContext, useState } from 'react';
import UIBasicTableGenerator from '../info-data-table/components/BasicDataTable';
import { GridType, INSERT_MODE, EDIT_MODE } from '../constants/appeng.enum';
import SkeletonCore from '../components/SkeletonCore';
import { useFetchAppGridData } from '../custom-hooks/useFetchAppGridData';
import { getPrimaryDBCodeExtractor, getFormId } from '../utils/ConfigUtils';
import { PATHNAME } from '../constants/appeng.enum';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import filterFields from './mockData/queryBuilder.json';
import { QueryBuilderData } from 'info-ui-library';
import { ThemeContext } from '../theme/ThemeContext';
import { getGridData } from '../actions';
import queryState from './mockData/queryState.json';
import queryUser from './mockData/queryUser.json';
import styled from 'styled-components';

const StyledSpinner: any = styled.div`
  margin: auto !important;
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1031 !important;
`;
const PortalGridGenerator: React.FC<any> = (props: any) => {
  const currentLocation = useLocation();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const userDetails = useSelector((state: any) => state.appState.userDetails);
  const routerId =
    props.referenceData &&
    props.referenceData[PATHNAME] &&
    props.referenceData[PATHNAME].split('/')[
      props.referenceData[PATHNAME].split('/').length - 1
    ];

  const { loadingApp, appData } = useFetchAppGridData(
    props.portalGrid.gridId,
    props.referenceData,
    '/app/ce/grid/' + routerId,
    props.nodeId,
    props.portalGrid.ceId,
    props.referralId,
    props.refreshCardData,
    false,
    props.parentId,
    props.portalId,
    props.referenceData.AE_RELOAD,
    props.tabRefresh
  );

  const portalLoading = useSelector(
    (state: any) => state.appState.portalDetails.loading,
    (prev, next) => prev === next
  );
  let cardsData = useSelector(
    (state: any) => state.appState.cardsData[props.referralId]
  );
  if (loadingApp || portalLoading) return <SkeletonCore count={1} />;
  try {
    const primaryDBCode = getPrimaryDBCodeExtractor(
      props.compositeEntityNodeData
    );

    const insertFormId = props.compositeEntityNodeData.CompositeEntityNode
      ? getFormId(
          props.compositeEntityNodeData.CompositeEntityNode,
          INSERT_MODE
        )
      : props.compositeEntityNodeData.DataGrid.form
        ? props.compositeEntityNodeData.DataGrid.form.configObjectId
        : null;

    const editFormId = props.compositeEntityNodeData.CompositeEntityNode
      ? getFormId(props.compositeEntityNodeData.CompositeEntityNode, EDIT_MODE)
      : props.compositeEntityNodeData.DataGrid.form
        ? props.compositeEntityNodeData.DataGrid.form.configObjectId
        : null;

    const datagrid = props.compositeEntityNodeData.CompositeEntityNode
      ? props.compositeEntityNodeData.CompositeEntityNode.dataGrid
      : props.compositeEntityNodeData.DataGrid;

    const entityName = props.compositeEntityNodeData.CompositeEntityNode
      ? props.compositeEntityNodeData.CompositeEntityNode.entity.name
      : props.compositeEntityNodeData.DataGrid.form
        ? props.compositeEntityNodeData.DataGrid.form.logicalEntity
          ? props.compositeEntityNodeData.DataGrid.form.logicalEntity
              .configObjectName
          : null
        : null;

    const entityId = props.compositeEntityNodeData.CompositeEntityNode
      ? props.compositeEntityNodeData.CompositeEntityNode.entity.configObjectId
      : props.compositeEntityNodeData.DataGrid.form
        ? props.compositeEntityNodeData.DataGrid.form.logicalEntity
          ? props.compositeEntityNodeData.DataGrid.form.logicalEntity
              .configObjectId
          : null
        : null;

    const nodeId = props.compositeEntityNodeData.CompositeEntityNode
      ? props.compositeEntityNodeData.CompositeEntityNode.configObjectId
      : null;

    const handleSearch = (newQuery: any) => {
    
      let referenceData = props.referenceData ? props.referenceData : {};
      referenceData['advanceQueryString'] = newQuery;
      dispatch(
        getGridData(
          props.portalGrid.gridId,
          referenceData,
          props.referralId,
          false,
          props.parentId,
          props.portalId,
          props.tabRefresh
        )
       
      );
     
    };

    const newProps = {
      ...props,
      isPortalGrid: true,
      datagrid: datagrid,
      entityName: entityName,
      entityId: entityId,
      nodeId: nodeId,
      gridType: GridType.PARENT,
      gridData: cardsData.data,
      primaryDBCode: primaryDBCode,
      insertFormId: insertFormId,
      editFormId: editFormId,
      usePortalReferenceData: props.usePortalReferenceData,
      portalId: props.portalId,
      parentId: props.parentId,
      resetOtherGrids: props.resetOtherGrids,
      location: currentLocation,
    };
    let loading = cardsData.buttonClicked ? true : false;
    return (
      <React.Fragment>
        {datagrid.isHavingAdvanceFilterForm && (
          <QueryBuilderData
            fields={getQueryFields(filterFields, userDetails)}
            handleSearch={handleSearch}
            themeContext={themeContext}
          />
        )}
        <div className={loading ? 'disable-div' : 'enable-div'}>
          {loading && (
            <StyledSpinner className="spinner-border">
              <span className="sr-only">Loading...</span>
            </StyledSpinner>
          )}
        </div>
        <UIBasicTableGenerator {...newProps} />
      </React.Fragment>
    );
  } catch {
    return <div>No data available...</div>;
  }
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.referenceData) ===
      JSON.stringify(nextProps.referenceData) &&
    prevProps.editableGridEnable === nextProps.editableGridEnable
  );
}

const getQueryFields = (filterFields, userDetails) => {
  
  filterFields.filter((field: any) => {
    switch (field.name) {
      case 'CND_STATE': {
        const stateList = [];
        JSON.parse(userDetails)
          ?.AdditionalDetails?.UserContext?.APP_LOGGED_IN_USER_CONTEXT_COUNTRY_ID?.split(
            ','
          )
          .map((key: any) => {
            queryState
              .filter(
                (country: any) => country.id === key.replaceAll("'", '')
              )[0]
              .state.map((stateValue: any) => {
                stateList.push(stateValue);
              });
          });

        field.values = stateList;
        break;
      }

      case 'COUNTRY':{
        field.values = JSON.parse(userDetails).SpecialMappingDetails[0]?.Country_LIST;
        break;
      }

      case 'cn.AE_INSERT_ID':{
        const userList=[];
        queryUser.map((user: any)=>{
          userList.push({
            label: user.FIRST_NAME +' '+ user.LAST_NAME,
            value: user.AE_USER_PROFILE_UUID
          })
        })
        field.values = userList;
        break;
      }
    }
  });
  return filterFields;
};

export default React.memo(PortalGridGenerator, isPropsAreEqual);
