import React, { useEffect, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import moment from 'moment';
import DOMPurify from 'dompurify';

const PreRenderer = ({ cell, row, column: { id } }) => {
  const [value, setValue] = React.useState(cell.value);
  const [rowOriginal, setRowOriginal] = React.useState([row.original]);

  const divRef = useRef(null);
  const [isTooltipEnable, setIsTooltipEnable] = useState(false);
  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);

  useEffect(() => {
    if (divRef.current) {
      const { scrollWidth, clientWidth } = divRef.current;
      setIsTooltipEnable(scrollWidth > clientWidth);
    }
  }, [divRef.current?.innerText]);

  const timeConverter = (valDate) => {
    return moment.utc(valDate).local().format('DD-MMM-YYYY h:mm:ss A');
  };

  {
    var evaluate = rowOriginal.map((key) => {
      try {
        return value ? eval('`' + value + '`') : '';
      } catch (e) {
        console.log(':::::PreRenderer:::::', e.message, value);
      }
    });
  }

  const sanitizedData = () => ({
    __html: evaluate,
  });
  
  let sanitizedTooltipContent = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
  sanitizedTooltipContent = sanitizedTooltipContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

  return cell.isRepeatedValue !== true &&
    value !== undefined &&
    value !== null &&
    !cell.column.isEllipsesEnable ? (
    <div
      dangerouslySetInnerHTML={sanitizedData()}
      style={{ padding: '10px 10px', lineHeight: 'normal' }}
    />
  ) : cell.isRepeatedValue !== true &&
    value !== undefined &&
    value !== null &&
    cell.column.isEllipsesEnable ? (
    <>
      <div
        ref={divRef}
        data-tooltip-id={isTooltipEnable ? `${cell.column.Header}-${row.index}` : null} 
        data-tooltip-content={sanitizedTooltipContent}
        style={{
          padding: '5px 5px',
          overflow: 'hidden',
          width: '100%',
          overflowWrap: 'break-word',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          wordBreak: 'break-all',
        }}
        dangerouslySetInnerHTML={sanitizedData()}
      />
      {isTooltipEnable && (
        <ReactTooltip
          place="bottom"
          style={{
            zIndex: 9999,
            color: 'white !important',
            maxWidth: '800px', 
            wordWrap: 'break-word', 
          }}
          id={`${cell.column.Header}-${row.index}`} 
        />
      )}
    </>
  ) : (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
    </React.Fragment>
  );
};

export default React.memo(PreRenderer);
