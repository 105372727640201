import React, { useCallback, useContext, useEffect } from 'react';

import { CheckBox, FormErrorContainers } from 'info-ui-library';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { extractLabel } from '../utils/intlutils';
import { ThemeContext } from '../theme/ThemeContext';
import { useFormData } from '../custom-hooks/useFormData';
import { changeFormData, getOptions } from '../actions/index';
import { IState } from '../store/index';
import { useErrorData } from '../custom-hooks/useErrorData';
import {
  processRadioButtonOptions,
  processErrorData,
} from './ComponentsCommonUtils';
import { ICheckBoxCore } from './interfaces/index';
import { useLocation } from 'react-router';

const CheckBoxCore: React.FC<ICheckBoxCore> = (props: ICheckBoxCore) => {
  const currentLocation = useLocation();

  const existingCards = useSelector((state: any) => state.appState.cardsData);
  const options = existingCards[props.referralId].options;
  const id = props.parentFormId ? props.parentFormId : props.formId;
  const key =
    id + '_' + props.occuranceNumber + '_' + props.configProp.configObjectId;
  const selectedOptions = options[key];

  let parsedOption = processRadioButtonOptions(
    props.configProp.multivalueList,
    props.intl.locale,
    selectedOptions
  );

  useEffect(() => {
    if (
      props.configProp.selectItemsReferenceID &&
      !props.configProp.multivalueList
    ) {
      dispatch(
        getOptions(
          props.parentFormId,
          props.formId,
          props.configProp,
          props.occuranceNumber,
          props.nodeId,
          props.referralId,
          currentLocation
        )
      );
    }
  }, [props.occuranceNumber]);

  const dbCode = props.configProp.logicalColumn
    ? props.configProp.logicalColumn.dbCode
    : props.configProp.dbCode;
  let initialValue = useFormData(
    props.formId,
    props.occuranceNumber,
    dbCode,
    props.parentFormId,
    props.referralId
  );

  let listOfSelectedOption: string[] = [];
  if (initialValue && initialValue !== null) {
    initialValue.split(',').map((value: any) => {
      listOfSelectedOption.push(value);
      return null;
    });
  }

  let errorData: any = useErrorData(props.location, props.referralId);
  let parsedError = processErrorData(errorData, props.intl.locale);

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  try {
    const onChange = useCallback((e: any, optionKey: any) => {
      if (e.target.checked) {
        listOfSelectedOption.push(optionKey);
      } else if (!e.target.checked) {
        var index = listOfSelectedOption.indexOf(optionKey);
        if (index > -1) {
          listOfSelectedOption.splice(index, 1);
        }
      }
      dispatch(
        changeFormData(
          props.formId,
          props.occuranceNumber,
          dbCode,
          listOfSelectedOption.join(),
          props.parentFormId,
          props.mode,
          props.nodeId,
          props.configProp.configObjectId,
          props.configProp.refreshFormOnChange,
          props.configProp.resetOnRefresh,
          props.configProp.placeHolder,
          props.referralId,
          currentLocation
        )
      );
    }, [listOfSelectedOption]);

    return (
      <CheckBox
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        key={props.configProp.configObjectId}
        value={listOfSelectedOption}
        displayType={themeContext.componentType}
        options={parsedOption}
        onChange={(e: any, optionKey: any) => onChange(e, optionKey)}
        errorMessage={parsedError}
        hasError={errorData ? true : false}
        disabled={!props.privilegesMap.isEditable}
        mandatory={
          props.configProp.isMandatory && props.mandatoryIconAccessibilityRegex
        }
        configId={props.configProp.configObjectId}
      />
    );
  } catch {
    const error = ['Error occurred in Check box core'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(CheckBoxCore));
