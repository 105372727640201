import React, { useEffect, useState, lazy } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useGroupBy,
  useExpanded,
  useRowSelect,
} from 'react-table';
import { Table, Tbody, Td, Thead, Th, Tr, getIconDef } from 'info-ui-library';
import { filterTypes, defaultColumn } from '../DefaultConstants';
import TablePagination from './Pagination';
import DataGridToolTip from '../editableGridComponents/DataGridToolTip';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import NestedComponent from './NestedComponent';
import {
  getPortalGridSelectedRowData,
  setGridPageIndex,
} from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';

function InfoTable(props) {
  const [enableGridFilter, setEnableGridFilter] = useState(false);

  const cardsData = useSelector(
    (state) => state.appState.cardsData[props.referralId]
  );
  const dispatch = useDispatch();
  const columns = props.columns;
  const data = cardsData.data;
  const errorData = props.errorDataExtractor
    ? props.errorDataExtractor('abc', 'UUID', props.referralId, false)
    : props.errorMap
      ? props.errorMap
      : null;
  const updateMyData = props.updateMyData;
  const serverPaginationEnable = props.serverPaginationEnable;
  const headerVisible = props.datagrid.isHeaderAvailable;
  const subComponentEnable = props.subComponentEnable;
  const subComponentType = props.subComponentType;
  const pageCountCopy = props.pageCount;
  const [selectedRowPath, setSelectedRowPath] = React.useState(null);
  const pageCalculatedIndex =
    props.pageCalculatedIndex === undefined ? 0 : props.pageCalculatedIndex;
  const cardsDataSatus = props.cardsDataSatus;

  const rowHeaderButtonClick = (gridHeaderButtons, props) => {
    if (gridHeaderButtons.buttonClass === 'gridFilterActionButton') {
      setEnableGridFilter(!enableGridFilter);
    } else {
      dispatch(setGridPageIndex(props.referralId, 0, false));
      props.gridHeaderButtonsClick(gridHeaderButtons, props);
    }
  };
  const onClick = (row) => {
    if (props.isRowSelectionEnable) {
      dispatch(
        getPortalGridSelectedRowData(
          props.referralId,
          row ? row.values : undefined,
          props.usePortalReferenceData,
          props.parentId,
          props.portalId
        )
      );
      setSelectedRowPath(row ? row.id.toString() : undefined);
    }
  };

  // Calculate total row count and page count before using `useTable`
  const totalRowCount =
    parseInt(cardsData?.paginatedDetails?.ae_pagination_total_count) || 0;
  const calculatedPageCount = parseInt(cardsData?.paginatedDetails?.limit)
    ? Math.ceil(totalRowCount / parseInt(cardsData?.paginatedDetails?.limit))
    : Math.ceil(totalRowCount / 10);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    filteredRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    rowCount,
    setAllFilters,
    state: { pageIndex, pageSize, filters, groupBy, expanded },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateMyData,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: serverPaginationEnable,
      autoResetExpanded: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
      ...(serverPaginationEnable && { pageCount: calculatedPageCount }),
      ...(serverPaginationEnable && { rowCount: totalRowCount }),
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (serverPaginationEnable) {
      props.fetchData({ pageIndex, pageSize });
    } else if (pageIndex !== undefined) {
    }
  }, [pageIndex, pageSize,serverPaginationEnable]);

  useEffect(() => {
    if (!enableGridFilter) {
      setAllFilters([]);
    }
  }, [enableGridFilter]);

  const renderRowItem = React.useCallback(
    (index, selectedPath, errorOccurred, selectedRowPath) => {
      const row = page[index];
      let status = 'normal';
      if (
        row.id.toString() === selectedPath &&
        errorOccurred &&
        errorOccurred[selectedPath]
      ) {
        status = 'fail';
      } else if (row.id.toString() === selectedPath) {
        status = 'success';
      } else if (row.id.toString() === selectedRowPath) {
        status = 'selected';
        row.isSelected = true;
      }
      prepareRow(row);
      return (
        <React.Fragment>
          <Tr
            {...row.getRowProps()}
            styleName={props.styleName}
            ioTheme={props.ioTheme}
            ioMode={props.ioMode}
            id={props.datagrid.configObjectId}
            key={props.datagrid.configObjectId}
            hoverEnable={props.hoverEnable}
            strippedEnable={props.strippedEnable}
            status={status}
            style={{ lineHeight: `20px` }}
            onClick={() => onClick(row)}
          >
            {row.cells.map((cell, index) => {
              if (cell.column.isAccessible) {
                let tdProps = cell.getCellProps();
                tdProps['style'] = {};
                tdProps.style['padding'] = '0.1rem';
                tdProps.style['position'] = 'relative';
                tdProps.style['verticalAlign'] = 'middle';
                tdProps.style['maxWidth'] = cell.column.width + 'px';
                tdProps.style['textAlign'] = cell.column.columnDataAlignment
                  ? cell.column.columnDataAlignment
                  : props.gridDataAlignment
                    ? props.gridDataAlignment
                    : 'left';

                return (
                  <Td
                    {...tdProps}
                    styleName={props.styleName}
                    ioTheme={props.ioTheme}
                    ioMode={props.ioMode}
                    borderEnable={props.borderEnable}
                    key={'row_cell_' + index}
                  >
                    {/* if cell is grouped, icons for first column whether it is grouped or expanded*/}
                    {cell.isGrouped ? (
                      <React.Fragment key={index + cell.configObjectId}>
                        {row.isExpanded ? (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            {...row.getToggleRowExpandedProps()}
                          />
                        ) : (
                          <FontAwesomeIcon
                            {...row.getToggleRowExpandedProps()}
                            icon={faPlay}
                          />
                        )}
                        {' ' + cell.value + ' '} ({row.subRows.length})
                      </React.Fragment>
                    ) : cell.isAggregated ? (
                      //smhgduesg uehudbswh hgf hufb
                      cell.render('Aggregated')
                    ) : !cell.isAggregated && cell.isRepeatedValue ? null : (
                      cell.render('Cell', {
                        editable: true,
                        subComponentEnable: subComponentEnable,
                      })
                    )}
                  </Td>
                );
              }
            })}
          </Tr>
          {/* if subrows are present*/}
          {(row.nestedComponentType || row.original['ACTION_TYPE']) &&
          row.isExpanded &&
          row.subRows.length === 0 ? (
            <Tr
              styleName={props.styleName}
              ioTheme={props.ioTheme}
              ioMode={props.ioMode}
              hoverEnable={props.hoverEnable}
              strippedEnable={props.strippedEnable}
              status={'expandedRow'}
              style={{ zIndex: 1, position: 'static' }}
              key={'subrows_' + row.configObjectId}
            >
              <Td
                style={{ padding: '1px', textOverflow: 'ellipsis' }}
                colSpan={visibleColumns.length}
                styleName={props.styleName}
                ioTheme={props.ioTheme}
                ioMode={props.ioMode}
                borderEnable={props.borderEnable}
              >
                {NestedComponent({ row, ...props })}
              </Td>
            </Tr>
          ) : null}
        </React.Fragment>
      );
    },
    [prepareRow, page]
  );

  const conditionallyRenderHeaderButton = (gridHeaderButtons) => {
    let showInlineAddButton = window.innerWidth < 750 ? true : false;
    if (
      showInlineAddButton &&
      gridHeaderButtons?.buttonClass == 'addRowInGrid'
    ) {
      return false;
    } else {
      return true;
    }
  };
  let tableProps = { ...getTableProps() };
  tableProps['style'] = {};

  return (
    <React.Fragment>
      <Table
        {...tableProps}
        styleName={props.styleName}
        ioTheme={props.ioTheme}
        ioMode={props.ioMode}
        id={props.datagrid.configObjectId}
        key={props.datagrid.configObjectId}
        borderEnable={props.borderEnable}
        strippedEnable={props.strippedEnable}
        hoverEnable={props.hoverEnable}
      >
        {headerVisible ? (
          <Thead
            styleName={props.styleName}
            ioTheme={props.ioTheme}
            ioMode={props.ioMode}
            borderEnable={props.borderEnable}
          >
            {headerGroups.map((headerGroup, index) => {
              return (
                <Tr
                  {...headerGroup.getHeaderGroupProps()}
                  styleName={props.styleName}
                  ioTheme={props.ioTheme}
                  ioMode={props.ioMode}
                  key={'headerGroup_' + headerGroup.id}
                  hoverEnable={false}
                  strippedEnable={false}
                  status={'normal'}
                  onClick={() => onClick(undefined)}
                >
                  {headerGroup.headers.map((column, index) => {
                    if (
                      column.isAccessible === undefined ||
                      column.isAccessible
                    ) {
                      let thProps = { ...column.getHeaderProps() };
                      thProps['style'] = {};
                      thProps.style['width'] = column.width;
                      thProps.style['max-width'] = column.width;
                      thProps.style['font-size'] = '21px';
                      if (
                        column &&
                        column.parentProps &&
                        column.parentProps.datagrid &&
                        column.parentProps.datagrid.dataGridColumns
                      ) {
                        column.parentProps.datagrid.dataGridColumns.map(
                          (dataColumnDetails) => {
                            if (
                              dataColumnDetails.headerName === column.Header &&
                              column.Header !== ''
                            ) {
                              if (dataColumnDetails.formField) {
                                column['isSortEnable'] = false;
                              } else {
                                column['isSortEnable'] = true;
                              }
                            }
                          }
                        );
                      }
                      return (
                        <Th
                          {...thProps}
                          styleName={props.styleName}
                          id={column.configObjectId}
                          key={
                            'column_' + index + props.datagrid.configObjectId
                          }
                          ioTheme={props.ioTheme}
                          ioMode={props.ioMode}
                          borderEnable={props.borderEnable}
                          style={{
                            fontSize: '18px',
                            fontWeight: 'normal',
                            lineHeight: '14px',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            paddingRight: '12px',
                            paddingLeft: '12px',
                            textAlign: column.columnHeaderAlignment
                              ? column.columnHeaderAlignment
                              : props.gridHeaderAlignment
                                ? props.gridHeaderAlignment
                                : 'left',
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            {props.gridHeaderButtons.map(
                              (gridHeaderButtons, index) => {
                                if (
                                  column.id === gridHeaderButtons.dbCode &&
                                  props.loadedIcon &&
                                  conditionallyRenderHeaderButton(
                                    gridHeaderButtons
                                  )
                                ) {
                                  return (
                                    <div
                                      key={
                                        'gridbuttons_' +
                                        index +
                                        props.datagrid.configObjectId
                                      }
                                      style={{
                                        position: 'relative',
                                        top: '0px',
                                        display: 'flex',
                                        paddingLeft:
                                          gridHeaderButtons['style'] &&
                                          gridHeaderButtons['style'][
                                            'padding-left'
                                          ]
                                            ? gridHeaderButtons['style'][
                                                'padding-left'
                                              ]
                                            : '0px',
                                        paddingRight:
                                          gridHeaderButtons['style'] &&
                                          gridHeaderButtons['style'][
                                            'padding-right'
                                          ]
                                            ? gridHeaderButtons['style'][
                                                'padding-right'
                                              ]
                                            : '0px',
                                      }}
                                      className={getIconDef(
                                        gridHeaderButtons['icon']
                                      )}
                                      title={gridHeaderButtons['toolTip']}
                                      onClick={() =>
                                        rowHeaderButtonClick(
                                          gridHeaderButtons,
                                          props
                                        )
                                      }
                                    >
                                      {gridHeaderButtons['name']}
                                    </div>
                                  );
                                }
                              }
                            )}
                            {subComponentEnable === false &&
                            column.canGroupBy &&
                            column.groupByEnable ? (
                              <div
                                {...column.getGroupByToggleProps()}
                                key={
                                  'column_2' +
                                  index +
                                  props.datagrid.configObjectId
                                }
                              >
                                {column.groupByEnable ? (
                                  <React.Fragment>
                                    <FontAwesomeIcon
                                      title="Toggle GroupBy"
                                      icon={
                                        column.isGrouped
                                          ? faChevronDown
                                          : faChevronUp
                                      }
                                      style={{
                                        marginTop: '4px',
                                        float: 'left',
                                        paddingRight: '3px',
                                        fontSize: '16px',
                                      }}
                                    />
                                    <FontAwesomeIcon
                                      title="Toggle GroupBy"
                                      icon={
                                        column.isGrouped
                                          ? faChevronDown
                                          : faChevronUp
                                      }
                                      style={{
                                        clear: 'left',
                                        float: 'left',
                                        paddingRight: '3px',
                                        fontSize: '16px',
                                        marginTop: '-5px',
                                      }}
                                    />
                                  </React.Fragment>
                                ) : (
                                  ''
                                )}
                              </div>
                            ) : null}
                            <span
                              title=""
                              {...(column['isSortEnable'] &&
                                column.getSortByToggleProps())}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              {
                                <DataGridToolTip
                                  width={column.width}
                                  tooltipValue={column.Header}
                                />
                              }

                              {column.Header !== '' && column.canSort ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <FontAwesomeIcon
                                      style={{
                                        paddingLeft: '5px',
                                        lineHeight: '8px',
                                      }}
                                      icon={faCaretDown}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      style={{
                                        paddingLeft: '5px',
                                        lineHeight: '8px',
                                      }}
                                      icon={faCaretUp}
                                    />
                                  )
                                ) : props.datagrid.defaultSorting ? (
                                  <FontAwesomeIcon
                                    style={{
                                      paddingLeft: '5px',
                                      lineHeight: '8px',
                                    }}
                                    icon={
                                      props.datagrid.defaultSorting
                                        ? faSort
                                        : ''
                                    }
                                  />
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </span>
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            {column.canFilter &&
                            enableGridFilter &&
                            props.loadedIcon
                              ? column.render('Filter')
                              : null}
                          </div>
                        </Th>
                      );
                    }
                  })}
                </Tr>
              );
            })}
          </Thead>
        ) : (
          ''
        )}
        <Tbody
          {...getTableBodyProps()}
          styleName={props.styleName}
          ioTheme={props.ioTheme}
          ioMode={props.ioMode}
        >
          {cardsDataSatus === undefined ? (
            <Tr
              styleName={props.styleName}
              ioTheme={props.ioTheme}
              ioMode={props.ioMode}
              hoverEnable={props.hoverEnable}
              strippedEnable={props.strippedEnable}
              status={'normal'}
            >
              <Td
                colSpan={visibleColumns.length}
                styleName={props.styleName}
                ioTheme={props.ioTheme}
                ioMode={props.ioMode}
                borderEnable={props.borderEnable}
              >
                {'Loading...'}
              </Td>
            </Tr>
          ) : (
            ''
          )}
          {cardsDataSatus === 'empty' ? (
            <Tr
              styleName={props.styleName}
              ioTheme={props.ioTheme}
              ioMode={props.ioMode}
              hoverEnable={props.hoverEnable}
              strippedEnable={props.strippedEnable}
              status={'normal'}
            >
              <Td
                colSpan={visibleColumns.length}
                styleName={props.styleName}
                ioTheme={props.ioTheme}
                ioMode={props.ioMode}
                borderEnable={props.borderEnable}
              >
                {'No Data Found'}
              </Td>
            </Tr>
          ) : (
            ''
          )}
          {page.map((i, index) => {
            return renderRowItem(
              index,
              props.selectedPath,
              errorData,
              selectedRowPath
            );
          })}
        </Tbody>
      </Table>
      {data.length > 0 ? (
        <TablePagination
          page={page}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          pageCount={pageCount}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          setPageSize={setPageSize}
          pageSize={pageSize}
          nextPage={nextPage}
          previousPage={previousPage}
          styleName={props.styleName}
          ioTheme={props.ioTheme}
          ioMode={props.ioMode}
          filteredRows={filteredRows}
          totalRows={serverPaginationEnable ? rowCount : data.length}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

export default React.memo(InfoTable);
