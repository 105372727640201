import { put, all, fork, takeEvery, call, select } from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setToggleMessages,
  setEditableGridStatus,
  setGridData,
  ADD_ROW_IN_GRID,
  UPDATE_INLINE_RECORD,
  getGridData,
  removeRowFromGrid,
  REMOVE_ROW_FROM_GRID,
  SET_EDITABLE_GRID_STATUS_SAGA,
} from '../../actions';
import { UpsertMessage, CardType } from '../../constants/appeng.enum';
import cloneDeep from 'lodash/cloneDeep';
import { isValidPhoneNumber } from 'react-phone-number-input'

const getCard = (state: any) => state.appState.cardsData;

export default function* gridSaga() {
  yield all([
    fork(watchAddRowInGrid),
    fork(watchUpdateInlineRecord),
    fork(watchRemoveRowFromGrid),
    fork(watchSetEditableGridStatusSagas),
  ]);
}

function* watchAddRowInGrid() {
  yield takeEvery(ADD_ROW_IN_GRID, addRowInGridSaga);
}

function* watchUpdateInlineRecord() {
  yield takeEvery(UPDATE_INLINE_RECORD, updateInlineRecordSaga);
}

function* watchRemoveRowFromGrid() {
  yield takeEvery(REMOVE_ROW_FROM_GRID, removeRowFromGridSaga);
}

function* watchSetEditableGridStatusSagas() {
  yield takeEvery(SET_EDITABLE_GRID_STATUS_SAGA, setEditableGridStatusSagas);
}

function* addRowInGridSaga(action: any): Generator<any, any, any> {
  const { data } = yield call(
    base.getConfigData,
    'DataGridQuery',
    action.gridId
  );
  if (data.DataGrid.form && data.DataGrid.form.logicalEntity) {
    let existingCardData = yield select(getCard);
    let currentCard = cloneDeep(existingCardData[action.cardId]);
    let datas = [...currentCard.data];
    if (datas.length === 0 || (datas && datas[0] && !datas[0].hasOwnProperty("expander"))) {
      let dataMap: any = {};
      dataMap['expander'] = 'NewRow';
      data.DataGrid.dataGridColumns.map((dataGridColumn: any, index: any) => {
        let dbcode = dataGridColumn.formField
          ? dataGridColumn.formField.dbCode
            ? dataGridColumn.formField.dbCode
            : dataGridColumn.formField.logicalColumn.dbCode
          : dataGridColumn.logicalColumn
            ? dataGridColumn.logicalColumn.dbCode
            : dataGridColumn.dbCode
              ? dataGridColumn.dbCode
              : undefined;
        if (dbcode) {
          dataMap[dbcode] = '';
        }
      });
      let datas = [...currentCard.data];
      datas.unshift(dataMap);
      Object.assign(currentCard.data, datas);
      if (Object.entries(currentCard.datagridErrorData).length > 0) {
        currentCard.datagridErrorData = {};
      }
      yield put(setGridData(currentCard, false, true));
    } else {
      const messages: string[] = [];
      messages.push("Please add one record at a time.");
      yield put(setToggleMessages(messages));
    }
  }
}

function* updateInlineRecordSaga(action: any): Generator<any, any, any> {
  if (action.logicalEntityId) {
    let existingCardsData = yield select(getCard);
    const cardsData = cloneDeep(existingCardsData);
    const currentCardData = cardsData[action.referralId];
    currentCardData.buttonClicked =
      action.actionName == 'Insert'
        ? 'clickedSaveButton'
        : action.actionName == 'Update'
          ? 'clickedUpdateButton'
          : 'default';
    if (currentCardData.buttonClicked) {
      yield put(setGridData(currentCardData, false, true));
    }

    let errorObject = yield clientSideInvalidPhoneValidation(action);
    let result = yield getInlineUpsertRecordResponse(action, errorObject);
    currentCardData.buttonClicked = '';

    if (
      result &&
      result.errors &&
      result.errors.length > 0 &&
      result.code !== 512 &&
      typeof result.errors !== 'string'
    ) {
      if (action.setSelectedPath) {
        action.setSelectedPath(action.data.path);
      }
      let errorData: any = {};
      errorData['rowIndex'] = action.data.rowIndex;
      errorData['path'] = action.data.path;
      result.errors.map((err: any) => {
        const lastElement =
          err.location.split('=>')[err.location.split('=>').length - 1];
        if (isNaN(Number(lastElement))) {
          errorData[lastElement] = err.message;
        } else {
          errorData[lastElement] = err.message;
        }
        return null;
      });
      let existingCardData = yield select(getCard);
      let currentCard = existingCardData[action.referralId];
      currentCard.datagridErrorData[action.data.path] = errorData;
      currentCard.data[action.data.rowIndex][action.data.valueChangedFor] =
        action.data.valueMap[action.data.valueChangedFor];
      yield put(setGridData(currentCard, false, true));
    } else if (
      result &&
      result.errors &&
      (result.code === 512 || typeof result.errors == 'string')
    ) {
      const messages: string[] = [];
      messages.push(result.errors);
      yield put(setToggleMessages(messages));
    } else if (
      errorObject.isErrorOccured &&
      errorObject.errorArray &&
      errorObject.errorArray.length > 0
    ) {
      if (action.setSelectedPath) {
        action.setSelectedPath(action.data.path);
      }
      let errorData: any = {};
      errorData['rowIndex'] = action.data.rowIndex;
      errorData['path'] = action.data.path;
      errorObject.errorArray.map((err: any) => {
        const lastElement =
          err.location.split('=>')[err.location.split('=>').length - 1];
        if (isNaN(Number(lastElement))) {
          errorData[lastElement] = err.message;
        } else {
          errorData[lastElement] = err.message;
        }
        return null;
      });
      let existingCardData = yield select(getCard);
      let currentCard = existingCardData[action.referralId];
      currentCard.datagridErrorData[action.data.path] = errorData;
      currentCard.data[action.data.rowIndex][action.data.valueChangedFor] =
        action.data.valueMap[action.data.valueChangedFor];
      yield put(setGridData(currentCard, false, true));
    } else {
      if (action.setSelectedPath) {
        action.setSelectedPath(action.data.path);
      }
      let gridId =
        action && action.datagrid
          ? action.datagrid.configObjectId
          : action && action.data && action.data.datagrid
            ? action.data.datagrid.configObjectId
            : null;
      let existingCardData = yield select(getCard);
      let currentCard = existingCardData[action.referralId];
      let dataRef = action.data && action.data.referenceData ? action.data.referenceData : action.data.valueMap ? action.data.valueMap : action.data;
      let formData: any = yield call(
        base.getFormData,
        gridId,
        action.primaryKey,
        dataRef
      );
      if (
        !formData[action.data.primaryDBCode] &&
        currentCard &&
        !formData.errors &&
        formData.code !== 512
      ) {
        let indexRemove = 0;
        currentCard.data.map((dataObj: any, index: any) => {
          if (dataObj[action.data.primaryDBCode] === action.primaryKey) {
            indexRemove = index;
          }
        });
        const messages: string[] = [];
        messages.push('Data is no longer present in this Grid');
        yield all([
          put(
            removeRowFromGrid(
              indexRemove,
              action.referralId,
              indexRemove.toString()
            )
          ),
          put(setToggleMessages(messages)),
        ]);
      } else if (formData && formData.errors && formData.code === 512) {
        const messages: string[] = [];
        messages.push(formData.errors);
        yield put(setToggleMessages(messages));
      } else if (action.actionName === 'Insert') {
        const messages: string[] = [];
        messages.push(UpsertMessage.SAVESUCCESSFUL);
        yield put(setToggleMessages(messages));
        yield put(
          getGridData(
            action.data.dataGridId,
            action.data.referenceData ? action.data.referenceData : {},
            action.referralId,
            false,
            existingCardData[action.referralId].parentId,
            existingCardData[action.referralId].portalId,
            false,
            action.data.path
          )
        );
      } else {
        for (const key of Object.keys(currentCard.data[action.data.rowIndex])) {
          if (formData.hasOwnProperty(key)) {
            currentCard.data[action.data.rowIndex][key] = formData[key];
          }
        }
        const cardData = new CardData(
          currentCard.parentId,
          CardType.PORTAL,
          currentCard.data,
          currentCard.portalId,
          currentCard.parentId,
          currentCard.referenceData,
          {},
          currentCard.options,
          currentCard.datagridErrorData
        );

        if (
          Object.entries(cardData.datagridErrorData).length > 0 &&
          cardData.datagridErrorData[action.data.path]
        ) {
          delete cardData.datagridErrorData[action.data.path];
        }
        yield put(setGridData(cardData, false, true));
      }
    }
  } else {
  }
}

function* getInlineUpsertRecordResponse(
  action: any,
  errorObject: any
): Generator<any, any, any> {
  let result;
  if (!errorObject.isErrorOccured && action && action.nodeId) {
    const { data } = yield call(base.getConfigData, 'NodeQuery', action.nodeId);
    const node = cloneDeep(data.CompositeEntityNode);
    const formId = base.getFormId(node, 'Update');
    const requestBody = yield createInlineRequestBody(
      action,
      node,
      action.referralId ? action.referralId : formId
    );
    result = yield call(base.upsertEntityRecord, requestBody);
  } else if (!errorObject.isErrorOccured && action.logicalEntityId) {
    let transactionName =
      action && action.datagrid && action.datagrid.label
        ? action.datagrid.label + '_' + action.actionName
        : action && action.datagrid && action.datagrid.name
          ? action.datagrid.name + '_' + action.actionName
          : action &&
            action.data &&
            action.data.datagrid &&
            action.data.datagrid.label
            ? action.data.datagrid.label + '_' + action.actionName
            : action &&
              action.data &&
              action.data.datagrid &&
              action.data.datagrid.name
              ? action.data.datagrid.name + '_' + action.actionName
              : '_' + action.actionName;
    let dataToSave = { ...action.data.valueMap };
    dataToSave['transactionName'] = transactionName;
    result = yield call(
      base.updateInlineRecord,
      action.primaryKey,
      action.logicalEntityId,
      dataToSave
    );
  }
  return result;
}

function* clientSideInvalidPhoneValidation(
  action: any
): Generator<any, any, any> {
  let errorMapKey: string = action.logicalEntityId + '=>0=>';

  let errorArray: any = [];
  let errorObject: any = {};
  errorObject['isErrorOccured'] = false;
  let logicalEntity: any =
    action && action.datagrid && action.datagrid.form
      ? action.datagrid.form.logicalEntity
      : action &&
        action.data &&
        action.data.datagrid &&
        action.data.datagrid.form
        ? action.data.datagrid.form.logicalEntity
        : null;
  let data = action.data && action.data.valueMap ? action.data.valueMap : null;
  if (logicalEntity) {
    logicalEntity.logicalColumns.map(async (logicalColumn: any, index: any) => {
      let errorMap: any = {};
      let componentErrorMessage = '';
      if (
        logicalColumn.dataType === 'PHONE_NUMBER' &&
        data[logicalColumn.dbCode]
      ) {
        componentErrorMessage = isValidPhoneNumber(data[logicalColumn.dbCode])
          ? ''
          : 'Invalid Phone Number';
      }
      let mandatoryError: any = base.clientSideMandatoryValidation(
        data[logicalColumn.dbCode],
        logicalColumn
      );
      let regexTypeError: any = base.clientSideRegexValidation(
        data[logicalColumn.dbCode],
        logicalColumn,
        data,
        'BOTH'
      );
      if (mandatoryError || regexTypeError || componentErrorMessage) {
        errorObject['isErrorOccured'] = true;
        let mandatoryErrorMessage = mandatoryError ? mandatoryError : '';
        let regexTypeErrorMessage = regexTypeError ? regexTypeError : '';

        errorMap['location'] = errorMapKey + logicalColumn.dbCode;
        errorMap['message'] =
          mandatoryErrorMessage + regexTypeErrorMessage + componentErrorMessage;
        errorMap['reason'] =
          mandatoryErrorMessage + regexTypeErrorMessage + componentErrorMessage;
        errorMap['warningMessage'] = '';
        errorArray.push(errorMap);
      }
    });
    errorObject['errorArray'] = errorArray;
    return errorObject;
  }
}

function* createInlineRequestBody(
  action: any,
  node: any,
  formId: any
): Generator<any, any, any> {
  try {
    const existingCardData = yield select(getCard);
    let parentFormData = action.data.valueMap;
    if (
      existingCardData[formId].data.attachmentData &&
      existingCardData[formId].data.attachmentData.length > 0
    ) {
      parentFormData.attachmentData = cloneDeep([
        ...existingCardData[formId].data.attachmentData,
      ]);
    }
    if (action.data.referenceData) {
      Object.assign(
        parentFormData,
        action.data.referenceData,
        base.getUserDetail()
      );
    }
    let processDefId;
    let userTaskId;
    if (existingCardData[formId].referenceData) {
      processDefId = existingCardData[formId].referenceData.processDefinitionId;
      userTaskId = existingCardData[formId].referenceData.userTaskId;
    }
    const requestBody: any = {};
    requestBody.action = action.actionName;
    requestBody.transactionName = action.transactionName
      ? action.transactionName
      : node.insertForm
        ? node.insertForm.formLabel + '_' + action.actionName
        : node.editForm
          ? node.editForm.formLabel + '_' + action.actionName
          : action.actionName;
    requestBody.baseEntity = {};
    requestBody.baseEntity.nodeId = action.nodeId;
    requestBody.baseEntity.configId = node.entity.configObjectId;
    requestBody.baseEntity.records = [];
    requestBody.baseEntity.records.push(parentFormData);
    requestBody.baseEntity.childEntities = [];
    if (processDefId && userTaskId && action.actionName !== 'Update') {
      requestBody.bpmnRequest = { processDefId, taskId: userTaskId };
    } else if (processDefId && action.actionName !== 'Update') {
      requestBody.bpmnRequest = { processDefId };
    }
    return requestBody;
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* removeRowFromGridSaga(action: any): Generator<any, any, any> {
  let existingCardData = yield select(getCard);
  let currentCard: any = cloneDeep(existingCardData[action.cardId]);
  let arrayDatas = currentCard.data
    .slice(0, action.rowIndex)
    .concat(
      currentCard.data.slice(action.rowIndex + 1, currentCard.data.length)
    );
  currentCard.data = arrayDatas;
  if (action.path && Object.entries(currentCard.datagridErrorData).length > 0) {
    currentCard.datagridErrorData = {};
  }
  yield put(setGridData(currentCard, false, true));
}

function* setEditableGridStatusSagas(action: any): Generator<any, any, any> {
  let existingCardData = yield select(getCard);
  let existingCard = existingCardData[action.cardId];

  if (existingCard) {
    let cardData = new CardData(
      action.cardId,
      existingCard.type,
      existingCard.data,
      existingCard.portalId,
      existingCard.parentId,
      existingCard.referenceData,
      existingCard.errorData,
      existingCard.options,
      existingCard.datagridErrorData,
      existingCard.toggleTabRefresh,
      action.isEditEnable
    );
    yield put(setEditableGridStatus(action.cardId, cardData));
  }
}
