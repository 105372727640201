import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setFormData,
  setToggleMessages,
  setStore,
  CHANGE_FORM_DATA,
  GET_OPTIONS,
  CUSTOM_FILTER,
} from '../../actions';
import {
  UpsertMessage,
  CardType,
  NodeType,
  ROOT_NODE_ID,
} from '../../constants/appeng.enum';
import { preprocessorExecuter } from '../executer/preprocessorExecuter';
import { isValidPhoneNumber } from 'react-phone-number-input'
const getCard = (state: any) => state.appState.cardsData;
const getExistingOptions = (state: any) => state.appState.options;

export default function* changeFormDataSaga() {
  yield all([
    fork(watchChangeFormData),
    fork(watchGetOptions),
    fork(watchCustomFilterSaga),
  ]);
}

function* watchChangeFormData() {
  yield takeLatest(CHANGE_FORM_DATA, changeFormData);
}

function* watchGetOptions() {
  yield takeEvery(GET_OPTIONS, getOptions);
}

function* watchCustomFilterSaga() {
  yield takeEvery(CUSTOM_FILTER, customFilterSaga);
}

function* changeFormData(action: any): Generator<any, any, any> {
  try {
    let existingCardData = yield select(getCard);
    const updateOptions = { ...existingCardData[action.referralId].options };
    let parentFormId = action.parentFormId
      ? action.parentFormId
      : action.formId;
    let formData = { ...existingCardData[action.referralId].data };
    const doUpdate = base.didUpdateRequired(
      action.parentFormId,
      action.formId,
      existingCardData,
      formData,
      action.dbCode,
      action.value,
      action.occuranceNumber,
      action.referralId
    );
    if (doUpdate) {
      let FFListNeedOption: string[] = [];
      let formSections: any[] = [];
      let logicalEntity: any = {};
      let parentLogicalEntityId: any = null;

      let resetFFList = action.resetOnRefresh
        ? action.resetOnRefresh.split(',')
        : [];

      base.updateFormData(
        action.parentFormId,
        action.formId,
        existingCardData,
        formData,
        action.dbCode,
        action.value,
        action.occuranceNumber,
        action.referralId
      );

      let configData;
      let reloadData = true;
      if (action.nodeId && action.nodeId !== ROOT_NODE_ID) {
        const { data } = yield call(
          base.getConfigData,
          'NodeQuery',
          action.nodeId
        );
        configData = data;
        formSections = base.getFormSections(
          configData,
          action.mode,
          action.parentFormId,
          action.formId
        );
        logicalEntity = base.getLogicalEntity(
          configData,
          action.parentFormId,
          action.formId
        );
        parentLogicalEntityId = action.parentFormId
          ? configData.CompositeEntityNode.entity.configObjectId
          : null;
      } else {
        const { data } = yield call(
          base.getConfigData,
          'FormQuery',
          parentFormId
        );
        configData = data;
        formSections = configData.Form.formSections;
        logicalEntity = configData.Form.logicalEntity;
        if (
          data.Form.formType === 'filterForm' &&
          !data.Form.filterFormReloadControl
        ) {
          reloadData = false;
        }
      }

      if (logicalEntity) {
        yield call(
          clientSideValidationHandler,
          action,
          logicalEntity,
          parentLogicalEntityId,
          formSections,
          formData
        );
      }

      try {
        if (action.refreshRequired) {
          if (resetFFList.length > 0) {
            FFListNeedOption = base.getFFListThatNeedOptions(
              formSections,
              formData,
              resetFFList,
              action.parentFormId,
              action.formId,
              action.occuranceNumber
            );
          }

          formData = yield call(
            preprocessorExecuter,
            action,
            formData,
            configData,
            existingCardData
          );

          if (FFListNeedOption.length > 0) {
            yield call(
              getOptionsDataInBulk,
              FFListNeedOption,
              formData[0],
              action.occuranceNumber,
              parentFormId,
              action.formId,
              updateOptions
            );

            const options = yield select(getExistingOptions);

            base.updateFFWhichHasOneSelectedOption(
              updateOptions,
              FFListNeedOption,
              parentFormId,
              action.occuranceNumber,
              formData,
              action.formId,
              action.dbCode,
              action.placeHolder,
              action.parentFormId
            );
          }
        }

        let updatedCardData = yield select(getCard);

        const cardData = new CardData(
          action.referralId,
          CardType.FORM,
          formData,
          existingCardData[action.referralId].portalId,
          existingCardData[action.referralId].parentId,
          existingCardData[action.referralId].referenceData,
          updatedCardData[action.referralId].errorData,
          updateOptions,
          {},
          existingCardData[action.referralId].toggleTabRefresh,
          existingCardData[action.referralId].editableGrid,
          existingCardData[action.referralId].workflowActions,
          existingCardData[action.referralId].tabGroupId,
          existingCardData[action.referralId].entityName,
          existingCardData[action.referralId].tabPortalId,
          existingCardData[action.referralId].entityId,
          existingCardData[action.referralId].parentCardId,
          existingCardData[action.referralId].enableFilterInGrid,
          existingCardData[action.referralId].buttonClicked,
          false,
          existingCardData[action.referralId].isAttachTypeApplicable,
          existingCardData[action.referralId].paginatedDetails
        );
        if (reloadData) {
          yield put(
            setFormData(cardData, action.referralId, false, false, false)
          );
        } else {
          yield put(
            setFormData(cardData, action.referralId, false, false, true)
          );
        }
      } catch (e) {
        const messages: string[] = [];
        messages.push(
          formData.message ? formData.message : UpsertMessage.UNSUCCESSFUL
        );
        yield put(setToggleMessages(messages));
      }
    }
  } catch (e) {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* clientSideValidationHandler(
  action: any,
  logicalEntity: any,
  parentLogicalEntityId: any,
  formSections: any,
  formData: any
): Generator<any, any, any> {
  let formField: any = null;
  let formFieldWithDBCode: any = {};
  formSections.map((formSection: any, index: any) => {
    formSection.formFields.map((formFieldMap: any, index: any) => {
      formFieldWithDBCode[formFieldMap?.configObjectId] =
        formFieldMap?.logicalColumn?.dbCode;
      if (formFieldMap.configObjectId === action.formFieldId) {
        formField = formFieldMap;
      }
    });
  });
  let formFieldToRefresh =
    formField && formField.resetOnRefresh
      ? formField.resetOnRefresh.split(',')
      : [];
  let logicalColumn =
    formField && formField.logicalColumn
      ? formField.logicalColumn
      : logicalEntity.logicalColumns.find(
          (lc: any) => lc.dbCode === action.dbCode
        );
  let componentErrorMessage = '';
  if (logicalColumn.dataType === 'PHONE_NUMBER' && action.value) {
    componentErrorMessage = isValidPhoneNumber(action.value)
      ? ''
      : 'Invalid Phone Number';
  }
  let lenghthError: any = base.clientSideLenghtValidation(
    action.value,
    logicalColumn
  );
  let mandatoryError: any = base.clientSideMandatoryValidation(
    action.value,
    logicalColumn
  );
  let dataTypeError: any = base.clientSideDataTypeValidation(
    action.value,
    logicalColumn
  );
  let regexTypeError: any = base.clientSideRegexValidation(
    action.value,
    logicalColumn,
    formData[0],
    'BOTH'
  );

  let parentKey: string = parentLogicalEntityId
    ? parentLogicalEntityId + '=>0=>'
    : '';
  let errorMapKey: string =
    parentKey +
    logicalEntity.configObjectId +
    '=>' +
    action.occuranceNumber +
    '=>' +
    action.dbCode;
  let errorrMessage: string = '';
  let isErrorOccured: boolean = false;
  if (
    lenghthError ||
    mandatoryError ||
    dataTypeError ||
    regexTypeError ||
    componentErrorMessage
  ) {
    isErrorOccured = true;
    let mandatoryErrorMessage = mandatoryError ? mandatoryError : '';
    let lenghthErrorMessage = lenghthError ? lenghthError : '';
    let dataTypeErrorMessage = dataTypeError ? dataTypeError : '';
    let regexTypeErrorMessage = regexTypeError ? regexTypeError : '';
    errorrMessage =
      mandatoryErrorMessage +
      lenghthErrorMessage +
      dataTypeErrorMessage +
      regexTypeErrorMessage +
      componentErrorMessage;
  }
  let existingCardsData = yield select(getCard);
  const cardsData = { ...existingCardsData };
  let cardData = cardsData[action.referralId];
  if (isErrorOccured) {
    if (Object.keys(cardData.errorData).length === 0) {
      const appError: any = {
        code: 406,
        errorData: {
          formFieldValidation: {},
          formValidation: {},
        },
      };
      appError.errorData.formFieldValidation[errorMapKey] = errorrMessage;
      cardData.errorData = appError;
      const updateStore: any = {
        cardsData: cardsData,
      };
      yield put(setStore(updateStore));
    } else {
      cardData.errorData.errorData.formFieldValidation[errorMapKey] =
        errorrMessage;
      const updateStore: any = {
        cardsData: cardsData,
      };
      yield put(setStore(updateStore));
    }
  } else if (!isErrorOccured && Object.keys(cardData.errorData).length > 0) {
    delete cardData.errorData.errorData.formFieldValidation[errorMapKey];
    for (let formFieldId of formFieldToRefresh) {
      if (formFieldWithDBCode.hasOwnProperty(formFieldId)) {
        let childField: string =
          parentKey +
          logicalEntity.configObjectId +
          '=>' +
          action.occuranceNumber +
          '=>' +
          formFieldWithDBCode[formFieldId];
        delete cardData.errorData.errorData.formFieldValidation[childField];
      }
    }
    const updateStore: any = {
      cardsData: cardsData,
    };
    yield put(setStore(updateStore));
  }
}

function* getOptionsDataInBulk(
  FFListNeedOption: any,
  formData: any,
  occuranceNumber: any,
  parentFormId: any,
  formId: any,
  updateOptions: any
): Generator<any, any, any> {
  try {
    yield all(
      FFListNeedOption.map((ff: any) => {
        const ffId = ff.split(':')[0];
        const selectedData =
          parentFormId !== formId
            ? formData.child[formId][occuranceNumber]
            : formData;
        return call(
          base.getOptionsDataInBulk,
          ffId,
          selectedData,
          occuranceNumber,
          parentFormId,
          updateOptions
        );
      })
    );
  } catch (e) {
    console.log('getOptionsInBulk error', e);
  }
}

function* getOptions(action: any): Generator<any, any, any> {
  try {
    let existingCardData = yield select(getCard);
    const id = action.parentFormId ? action.parentFormId : action.formId;
    let formData = existingCardData[action.referralId].data;
    let existingOptions = { ...existingCardData[action.referralId].options };
    let mergeData = { ...formData[0] };
    if (action && action.referenceData) {
      for (let [key, value] of Object.entries(formData[0])) {
        for (let [innerKey, innerValue] of Object.entries(
          action.referenceData
        )) {
          if (key === innerKey) {
            mergeData[innerKey] = innerValue;
          }
        }
      }
    }

    const selectedData =
      action.parentFormId && action.parentFormId !== action.formId
        ? formData[0].child[action.formId][action.occuranceNumber]
        : mergeData;
    const optionsData = yield call(
      base.getOptionsData,
      action.formFieldConfiData.configObjectId,
      selectedData
    );
    if (optionsData.errors && optionsData.code === 512) {
      const messages: string[] = [];
      messages.push(optionsData.errors);
      yield put(setToggleMessages(messages));
    } else {
      const optionAsList = base.generateoptionsInFormat(optionsData);

      const key =
        id +
        '_' +
        action.occuranceNumber +
        '_' +
        action.formFieldConfiData.configObjectId;
      let initialvalue;
      if (action.parentFormId) {
        initialvalue =
          formData[0].child[action.formId][action.occuranceNumber][
            action.formFieldConfiData.logicalColumn
              ? action.formFieldConfiData.logicalColumn.dbCode
              : action.formFieldConfiData.dbCode
          ];
      } else {
        initialvalue =
          formData[0][
            action.formFieldConfiData.logicalColumn
              ? action.formFieldConfiData.logicalColumn.dbCode
              : action.formFieldConfiData.dbCode
          ];
      }
      const shouldAddInFormData: boolean =
        !initialvalue &&
        optionAsList &&
        !action.formFieldConfiData.placeHolder &&
        optionAsList.length === 1
          ? true
          : false;

      if (shouldAddInFormData) {
        let updatedStoreCardData = yield select(getCard);
        const updatedValue = optionAsList[0]['id'];
        if (
          updatedStoreCardData[action.referralId] &&
          String(updatedValue) !== String(initialvalue)
        ) {
          let updateStorFormData = {
            ...updatedStoreCardData[action.referralId].data,
          };
          if (action.parentFormId) {
            updateStorFormData[0].child[action.formId][action.occuranceNumber][
              action.formFieldConfiData.logicalColumn.dbCode
            ] = updatedValue;
          } else {
            updateStorFormData[0][
              action.formFieldConfiData.logicalColumn.dbCode
            ] = updatedValue;
          }

          let updateStorOptions =
            updatedStoreCardData[action.referralId] &&
            updatedStoreCardData[action.referralId].options
              ? { ...updatedStoreCardData[action.referralId].options }
              : {};
          updateStorOptions[key] = optionsData;
          const cardData = new CardData(
            action.referralId,
            updatedStoreCardData[action.referralId].type,
            updateStorFormData,
            updatedStoreCardData[action.referralId].portalId,
            updatedStoreCardData[action.referralId].parentId,
            updatedStoreCardData[action.referralId].referenceData,
            updatedStoreCardData[action.referralId].errorData,
            updateStorOptions,
            updatedStoreCardData[action.referralId].datagridErrorData,
            updatedStoreCardData[action.referralId].toggleTabRefresh,
            updatedStoreCardData[action.referralId].editableGrid,
            updatedStoreCardData[action.referralId].workflowActions,
            updatedStoreCardData[action.referralId].tabGroupId,
            updatedStoreCardData[action.referralId].entityName,
            updatedStoreCardData[action.referralId].tabPortalId,
            updatedStoreCardData[action.referralId].entityId,
            updatedStoreCardData[action.referralId].parentCardId,
            updatedStoreCardData[action.referralId].enableFilterInGrid,
            updatedStoreCardData[action.referralId].buttonClicked,
            updatedStoreCardData[action.referralId].isPreprocessorRunning,
            updatedStoreCardData[action.referralId].isAttachTypeApplicable,
            updatedStoreCardData[action.referralId].paginatedDetails
          );
          let resetForms = true;
          if (action.cardId) {
            resetForms = false;
          }
          if (action.referralId) {
            resetForms = false;
          }
          yield put(
            setFormData(cardData, action.referralId, resetForms, false, false)
          );
        }
      } else {
        let updatedStoreCardData = yield select(getCard);
        if (updatedStoreCardData[action.referralId]) {
          let updateStorOptions =
            updatedStoreCardData[action.referralId] &&
            updatedStoreCardData[action.referralId].options
              ? { ...updatedStoreCardData[action.referralId].options }
              : {};
          updateStorOptions[key] = optionsData;
          const cardData = new CardData(
            action.referralId,
            updatedStoreCardData[action.referralId].type,
            updatedStoreCardData[action.referralId].data,
            updatedStoreCardData[action.referralId].portalId,
            updatedStoreCardData[action.referralId].parentId,
            updatedStoreCardData[action.referralId].referenceData,
            updatedStoreCardData[action.referralId].errorData,
            updateStorOptions,
            updatedStoreCardData[action.referralId].datagridErrorData,
            updatedStoreCardData[action.referralId].toggleTabRefresh,
            updatedStoreCardData[action.referralId].editableGrid,
            updatedStoreCardData[action.referralId].workflowActions,
            updatedStoreCardData[action.referralId].tabGroupId,
            updatedStoreCardData[action.referralId].entityName,
            updatedStoreCardData[action.referralId].tabPortalId,
            updatedStoreCardData[action.referralId].entityId,
            updatedStoreCardData[action.referralId].parentCardId,
            updatedStoreCardData[action.referralId].enableFilterInGrid,
            updatedStoreCardData[action.referralId].buttonClicked,
            updatedStoreCardData[action.referralId].isPreprocessorRunning,
            updatedStoreCardData[action.referralId].isAttachTypeApplicable,
            updatedStoreCardData[action.referralId].paginatedDetails
          );
          let resetForms = true;
          if (action.cardId) {
            resetForms = false;
          }
          if (action.referralId) {
            resetForms = false;
          }
          yield put(
            setFormData(cardData, action.referralId, resetForms, false, false)
          );
        }
      }
    }
  } catch (e) {
    console.log('getOptions error', e);
  }
}

function* customFilterSaga(action: any): Generator<any, any, any> {
  const { data } = yield call(base.getConfigData, 'PortalFormQuery', action.id);
  const formId = data.PortalForm.formId;
  let configdata: any;
  if (data.PortalForm) {
    const { data } = yield call(base.getConfigData, 'FormQuery', formId);
    configdata = data;
  }

  const existingCardData = { ...(yield select(getCard)) };
  let existingCard = existingCardData[action.cardId];
  let isErrorOccured = false;
  const errorMap: any = {};
  configdata.Form.formSections.map((formSection: any) => {
    formSection.formFields.map((ff: any) => {
      if (ff.logicalColumn) {
        let mandatoryError: any = base.clientSideMandatoryValidation(
          existingCard.data[0][ff.logicalColumn.dbCode],
          ff.logicalColumn
        );
        if (mandatoryError) {
          isErrorOccured = true;
          errorMap[
            configdata.Form.configObjectId + '=>0=>' + ff.logicalColumn.dbCode
          ] = mandatoryError;
        }
      }
    });
  });
  if (isErrorOccured) {
    if (Object.keys(existingCard.errorData).length === 0) {
      const appError = {
        code: 406,
        errorData: {
          formFieldValidation: {},
          formValidation: {},
        },
      };
      appError.errorData.formFieldValidation = errorMap;
      existingCard.errorData = appError;
      const updateStore: any = {
        cardsData: existingCardData,
      };
      yield put(setStore(updateStore));
    } else {
      Object.assign(
        existingCard.errorData.errorData.formFieldValidation,
        errorMap
      );
      const updateStore: any = {
        cardsData: existingCardData,
      };
      yield put(setStore(updateStore));
    }
  } else {
    const cardData = new CardData(
      action.cardId,
      existingCard.type,
      existingCard.data,
      existingCard.parentId,
      existingCard.parentId,
      existingCard.referenceData
    );
    yield put(setFormData(cardData, action.cardId, false, false, false));
  }
}
