import React, { useContext, lazy } from 'react';
import { TextBox } from 'info-ui-library';
import { isConfigObjectEditable } from '../../../composite-entity/form/CardGenerators';
import { UserContext } from '../../../user-context/UserContext';
import DataGridToolTip from './DataGridToolTip';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditableCell = ({ cell, row, column: { id }, updateMyData }) => {
  const [isEditable, setEditable] = React.useState(false);
  const [value, setValue] = React.useState(cell.value);
  const [previousValue, setPreviousValue] = React.useState(cell.value);
  const userContext = useContext(UserContext);

  //WHEN INLINE EDIT IS DISABLE AND ADD NEW ROW IS ENABLE
  let cellEditable =
    row.original &&
    row.original['expander'] === 'NewRow' &&
    row.values[cell.column.primaryDBCode] === ''
      ? true
      : false;

  let rowData = row.original;
  Object.assign(rowData, userContext.getUserDetail());
  let columnIsEditibale = isConfigObjectEditable(
    true,
    cell.column.privilege,
    cell.column.editabilityRegex,
    rowData,
    'EDIT',
    true
  );

  React.useEffect(() => {
    setValue(cell.value);
    setPreviousValue(cell.value);
  }, [cell.value]);

  const onChange = (e) => {
    // setPreviousValue(value);
    setValue(e);
  };

  let errorData = cell.column.useErrorDataUsingDBCode
    ? cell.column.useErrorDataUsingDBCode(
        row.id.toString(),
        id,
        cell.column.referralId,
        true
      )
    : null;

  React.useEffect(() => {
    if (
      errorData &&
      errorData.dbCode === id &&
      errorData.path === row.id.toString()
    ) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [errorData]);

  const handleBlur = (e) => {
    
    const timeout = setTimeout(() => {
      if (
        isEditable &&
        !(
          errorData &&
          errorData.dbCode === id &&
          errorData.path === row.id.toString()
        )
      ) {
        setEditable(false);
      }
      if (value !== previousValue && updateMyData) {
        // modified value after removing forward and backward spaces from the text
        let modifiedValue = value && value.trim();
        updateMyData(row.index, id, modifiedValue, row.id);
        setPreviousValue(value);
      }
    }, 10);
  };
  //By Raunak.. May need Further Improvement
  const handleMouseLeave = (e) => {
    if (isEditable && value == previousValue)
    {
      setEditable(false);
    }
  }
  const handleClick = () => {
    if (columnIsEditibale) {
      if (
        !(
          errorData &&
          errorData.dbCode === id &&
          errorData.path === row.id.toString()
        )
      ) {
        if (errorData || (errorData && errorData.path !== row.id.toString())) {
          setEditable(true);
        } else if (!isEditable) {
          setEditable(true);
        }
      }
    }
  };

  let tooltipID = 'textBoxError_' + id + '_' + row.id.toString();

  return columnIsEditibale &&
    (cell.column.editEnable || cellEditable) &&
    cell.column.isDefaultEditable &&
    cell.column?.changeEnable ? (
    <div
      style={{
        display: 'flex',
        'flex-wrap': 'wrap',
        width: cell.column.width + 'px',
        position: 'absolute',
      }}
    >
      {errorData && errorData['dbCode'] && errorData['dbCode'] === id ? (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          aria-hidden="true"
          data-for={tooltipID}
          data-tip={tooltipID}
          style={{
            'align-self': 'center',
            'padding-left': '3px',
            color: 'red',
          }}
        />
      ) : (
        ''
      )}
      <div
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
        style={{
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '2px solid red'
              : 'none',
          width:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '99%'
              : '99%',
          borderRadius: '6px',
        }}
      >
        <TextBox
          value={value}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => handleBlur(e)}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          displayType={'SingleLine'}
          isInlineGridComponent={true}
          isFilterComponent={true}
          style={{ border: 'unset', height: '40px' }}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            zIndex: 9999,
            color: 'white !important',
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    </div>
  ) : columnIsEditibale &&
    ((cell.column.editEnable && cellEditable && cell.column?.changeEnable) ||
      (cell.column.editEnable && isEditable && cell.column?.changeEnable)) ? (
    <React.Fragment>
      <div
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
        onBlur={(e) => handleBlur(e)}
        onClick={() => handleClick()}
        onMouseLeave={() => handleMouseLeave()}
        style={{
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '2px solid red'
              : 'none',
          width:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '99%'
              : '99%',
          borderRadius: '6px'
        }}
      >
        <TextBox
          value={value}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => handleBlur(e)}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          displayType={'SingleLine'}
          isInlineGridComponent={true}
          isFilterComponent={true}
          style={{
            border:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'unset'
                : '',
            height: '40px'
          }}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            zIndex: 9999,
            color: 'white !important',
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {cell.isRepeatedValue !== true &&
      value !== undefined &&
      value !== null &&
      !cell.column.isEllipsesEnable ? (
        <div
          onBlur={(e) => handleBlur(e)}
          onClick={() => handleClick()}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
          style={{ padding: "10px 10px", "lineHeight": "normal" }}
        ></div>
      ) : cell.isRepeatedValue !== true &&
        value !== undefined &&
        value !== null &&
        value !== '' &&
        cell.column.isEllipsesEnable ? (
        <div
          onBlur={(e) => handleBlur(e)}
          onClick={() => handleClick()}
          style={{
            padding: '10px 10px',
            // width: cell.column.width + 'px',
            height: '100%',
          }}
        >
          <DataGridToolTip width={cell.column.width} tooltipValue={value} />
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          style={{
            width: cell.column.width + 'px',
            height: '40px',
          }}
          onBlur={(e) => handleBlur(e)}
          onClick={() => handleClick()}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(EditableCell);