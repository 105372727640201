import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setFormData,
  setToggleMessages,
  GET_FORM_DATA,
  removeRowFromGrid,
  removeCardDataByReferralId,
  setStore,
  GET_REPORT_FORM_DATA,
} from '../../actions';
import { UpsertMessage, CardType, NodeType } from '../../constants/appeng.enum';

import { router } from '../../routes/Routes';
import { getPrimaryDBCode, getDispalyColumn } from '../../utils/ConfigUtils';
import cloneDeep from 'lodash/cloneDeep';
import { executeCompositeEntityPreprocessor } from '../executer/preprocessorExecuter';
import { getDynamicOptionParentFFList } from '../utilitySags';
const getCard = (state: any) => state.appState.cardsData;
const getToggleMessages = (state: any) => state.appState.toggleMessages;

export default function* getFormSaga() {
  yield all([fork(watchGetFormData), fork(watchGetReportFormData)]);
}

function* watchGetFormData() {
  yield takeEvery(GET_FORM_DATA, getFormDataSaga);
}

function* watchGetReportFormData() {
  yield takeEvery(GET_REPORT_FORM_DATA, getReportFormData);
}

function* getFormDataSaga(action: any): Generator<any, any, any> {
  const toggleMessages = yield select(getToggleMessages);
  if (toggleMessages.length) yield put(setToggleMessages([]));
  try {
    let optionFormList: any = [];
    let childOptionFormList: any = [];
    const { data } = yield call(base.getConfigData, 'NodeQuery', action.nodeId);
    const primaryKey = action.primaryKey;
    const primaryDBCode = getPrimaryDBCode(
      data.CompositeEntityNode.entity.logicalColumns
    );
    const displayColumn = getDispalyColumn(
      data.CompositeEntityNode.entity.logicalColumns
    );
    const displayName = displayColumn ? displayColumn.dbCode : primaryDBCode;
    const formId = base.getFormId(data.CompositeEntityNode, action.mode);
    let formData: any = {};
    const userSessionData = base.getUserDetail();
    let processDefinitionId;
    let userTaskId;
    if (action.ceId) {
      const ceData = yield call(base.getConfigData, 'CEQuery', action.ceId);
      // processDefinitionId =
      //   ceData.data.CompositeEntity.processDefinitionMapping &&
      //   ceData.data.CompositeEntity.processDefinitionMapping.length
      //     ? ceData.data.CompositeEntity.processDefinitionMapping[0]
      //         .processDefinitionId
      //     : null;
    }
    let workflowActions;

    if (action.mode !== 'Insert') {
      const parentForm = data.CompositeEntityNode.editForm
        ? data.CompositeEntityNode.editForm
        : data.CompositeEntityNode.insertForm;
      optionFormList.push(parentForm);

      formData[0] = yield call(
        base.getFormData,
        data.CompositeEntityNode.dataGrid.configObjectId,
        primaryKey,
        action.referenceData
      );

      if (formData[0].errors && formData[0].code === 512) {
        const messages: string[] = [];
        messages.push(formData[0].errors);
        yield put(setToggleMessages(messages));
      } else {
        formData[0].child = {};
        Object.assign(formData[0], userSessionData);
        if (data.CompositeEntityNode.combinedNodes) {
          for (const childNode of data.CompositeEntityNode.combinedNodes) {
            if (!childNode.dataGrid && childNode.addToParentEditForm) {
              const childData = yield call(
                base.getCombinedChildFormData,
                childNode.entity.configObjectId,
                formData[0]
              );
              const childFormId = base.getFormId(childNode, action.mode);
              const form = base.getForm(childNode, action.mode);
              childOptionFormList.push(form);
              if (Object.keys(childData).length && !childData.errors) {
                Object.entries(childData).forEach(
                  ([key, value]: [any, any]) => {
                    Object.assign(value, userSessionData);
                  }
                );
                formData[0].child[childFormId] = childData;
              } else if (childData.errors && childData.code === 512) {
                const messages: string[] = [];
                messages.push(childData.errors);
                yield put(setToggleMessages(messages));
              } else {
                formData[0].child[childFormId] = {};

                if (form.isRepeatable) {
                  let totalRecords = 0;
                  if (
                    form.defaultRepeatation &&
                    !form.maxRepeatation &&
                    !form.minRepeatation
                  ) {
                    totalRecords = form.defaultRepeatation;
                  }
                  if (
                    (form.minRepeatation && !form.defaultRepeatation) ||
                    (form.minRepeatation &&
                      form.defaultRepeatation &&
                      form.defaultRepeatation === form.minRepeatation)
                  ) {
                    totalRecords = form.minRepeatation;
                  }
                  for (let i: number = 1; i <= totalRecords; i++) {
                    formData[0].child[childFormId][0 - i] = {};

                    let childDefaultFormData = base.getDefaultDataMap(form);
                    Object.assign(
                      formData[0].child[childFormId][0 - i],
                      childDefaultFormData
                    );

                    form.formSections.map((formSection: any) => {
                      Object.assign(
                        formData[0].child[form.configObjectId][0 - i],
                        base.getDefaultFFMap(
                          formSection.formFields,
                          formData[0].child[form.configObjectId][0 - i]
                        )
                      );
                      return null;
                    });
                  }
                } else {
                  formData[0].child[childFormId][0] = {};

                  let childDefaultFormData = base.getDefaultDataMap(form);
                  Object.assign(
                    formData[0].child[childFormId][0],
                    childDefaultFormData
                  );

                  form.formSections.map((formSection: any) => {
                    Object.assign(
                      formData[0].child[form.configObjectId][0],
                      base.getDefaultFFMap(
                        formSection.formFields,
                        formData[0].child[form.configObjectId][0]
                      )
                    );
                    return null;
                  });
                }
              }
            }
          }
        }
      }
    } else {
      formData = {
        '0': {
          child: {},
        },
      };
      const parentForm = data.CompositeEntityNode.insertForm
        ? data.CompositeEntityNode.insertForm
        : data.CompositeEntityNode.editForm;
      optionFormList.push(parentForm);

      let defaultParentFormData = base.getDefaultDataMap(parentForm);
      Object.assign(formData[0], defaultParentFormData);

      parentForm.formSections.map((formSection: any) => {
        if (formSection.formFields)
          Object.assign(
            formData[0],
            base.getDefaultFFMap(
              formSection.formFields,
              formData[0],
              action.referenceData
            )
          );
        return null;
      });

      if (data.CompositeEntityNode.combinedNodes) {
        data.CompositeEntityNode.combinedNodes.map((childNode: any) => {
          if (
            !childNode.dataGrid &&
            childNode.addToParentDisplay &&
            childNode.addToParentInsertForm
          ) {
            const childForm = base.getForm(childNode, 'Insert');
            childOptionFormList.push(childForm);
            const childFormId = childForm.configObjectId;
            formData[0].child[childFormId] = {};

            if (childForm.isRepeatable) {
              let totalRecords = 0;
              if (
                childForm.defaultRepeatation &&
                !childForm.maxRepeatation &&
                !childForm.minRepeatation
              ) {
                totalRecords = childForm.defaultRepeatation;
              }
              if (
                (childForm.minRepeatation && !childForm.defaultRepeatation) ||
                (childForm.minRepeatation &&
                  childForm.defaultRepeatation &&
                  childForm.defaultRepeatation === childForm.minRepeatation)
              ) {
                totalRecords = childForm.minRepeatation;
              }
              for (let i: number = 1; i <= totalRecords; i++) {
                formData[0].child[childFormId][0 - i] = {};

                let childDefaultFormData = base.getDefaultDataMap(childForm);
                Object.assign(
                  formData[0].child[childFormId][0 - i],
                  childDefaultFormData
                );

                childForm.formSections.map((formSection: any) => {
                  Object.assign(
                    formData[0].child[childForm.configObjectId][0 - i],
                    base.getDefaultFFMap(
                      formSection.formFields,
                      formData[0].child[childForm.configObjectId][0 - i]
                    )
                  );
                  return null;
                });
              }
            } else {
              formData[0].child[childFormId][0] = {};

              let childDefaultFormData = base.getDefaultDataMap(childForm);
              Object.assign(
                formData[0].child[childFormId][0],
                childDefaultFormData
              );

              childForm.formSections.map((formSection: any) => {
                Object.assign(
                  formData[0].child[childForm.configObjectId][0],
                  base.getDefaultFFMap(
                    formSection.formFields,
                    formData[0].child[childForm.configObjectId][0]
                  )
                );
                return null;
              });
            }

            return null;
          }
          return null;
        });
      }
    }

    let existingCardDetails = yield select(getCard);
    let currentCardDetails = cloneDeep(
      existingCardDetails[action.parentCardId]
    );
    if (
      action.referenceData.actionType &&
      action.referenceData.actionType === 'UPSERT' &&
      formData[0][action.referenceData.primaryDBCode] === undefined &&
      currentCardDetails
    ) {
      let indexRemove = 0;
      currentCardDetails.data.map((dataObj: any, index: any) => {
        if (dataObj[action.referenceData.primaryDBCode] === action.primaryKey) {
          indexRemove = index;
        }
      });
      const messages: string[] = [];
      messages.push('Data is no longer present in this Grid');
      yield all([
        put(
          removeRowFromGrid(
            indexRemove,
            action.parentCardId,
            indexRemove.toString()
          )
        ),
        put(removeCardDataByReferralId(action.referralId)),
        put(removeCardDataByReferralId(action.parentId)),
        put(setToggleMessages(messages)),
      ]);
    } else {
      formData = yield call(
        executeCompositeEntityPreprocessor,
        data,
        formData,
        action.mode,
        true,
        [],
        action.referralId
      );
      if (formData.errors) {
        const messages: string[] = [];
        messages.push(formData.errors);
        yield put(setToggleMessages(messages));
      } else {
        let existingCardData = yield select(getCard);
        const existingOptions = existingCardData[action.referralId]
          ? { ...existingCardData[action.referralId].options }
          : {};

        yield call(
          getDynamicOptionParentFFList,
          existingCardData[action.referralId],
          optionFormList,
          formData,
          existingOptions
        );
        yield call(
          getDynamicOptionChildFFList,
          existingCardData[action.referralId],
          formData,
          existingOptions,
          childOptionFormList
        );

        let referenceData = existingCardData[action.referralId]
          ? existingCardData[action.referralId].referenceData
          : null;
        let rd =
          referenceData || action.referenceData
            ? { ...referenceData, ...action.referenceData }
            : {};
        // if (processDefinitionId) {
        //   rd.processDefinitionId = processDefinitionId;
        // }
        if (userTaskId) {
          rd.userTaskId = userTaskId;
        }
        let neededData = {
          primaryDBCode,
          insertFormId: formId,
          editFormId: formId,
          cardId: action.referralId,
          displayName: displayName,
          nodeId: action.nodeId,
          ceId: action.ceId,
          componentType:
            action.nodeType == undefined ||
            action.nodeType === '' ||
            action.nodeType === NodeType.PARENTNODE
              ? 'ParentForm'
              : 'ChildForm',
          data: formData[0],
          mode: action.mode,
          nodeType: action.nodeType,
        };

        if (
          !action.usePortalReferenceData &&
          existingCardData[action.referralId]
        ) {
          const cardData = new CardData(
            action.referralId,
            CardType.FORM,
            formData,
            action.portalId,
            action.parentId,
            rd,
            existingCardData[action.referralId].errorData,
            existingOptions,
            undefined,
            undefined,
            undefined,
            workflowActions,
            '',
            '',
            '',
            '',
            action.parentCardId,
            existingCardData[action.referralId].enableFilterInGrid,
            // existingCardData[action.referralId].buttonClicked,
            existingCardData[action.referralId].isPreprocessorRunning,
            existingCardData[action.referralId].isAttachTypeApplicable,
            existingCardData[action.referralId].paginatedDetails
          );
          const updateRouterParam: any = {};
          Object.assign(updateRouterParam, action.location.state);
          Object.assign(rd, action.location.state[action.parentId], neededData);
          updateRouterParam[action.parentId] = rd;
          let currentCard = cloneDeep(existingCardData[action.parentCardId]);
          const cardsData = cloneDeep(existingCardData);
          if (
            action.referenceData.actionType &&
            action.referenceData.actionType === 'UPSERT' &&
            action.parentCardId &&
            formData[0][action.referenceData.primaryDBCode] &&
            cardsData[action.parentCardId] &&
            cardsData[action.parentCardId].data
          ) {
            cardsData[action.parentCardId].data.map((dataObj: any) => {
              if (
                dataObj[action.referenceData.primaryDBCode] ===
                action.primaryKey
              ) {
                for (const key of Object.keys(dataObj)) {
                  if (formData[0].hasOwnProperty(key)) {
                    dataObj[key] = formData[0][key];
                  }
                }
              }
            });
          }
          if (
            action.referenceData.actionType &&
            action.referenceData.actionType === 'UPSERT' &&
            formData[0][action.referenceData.primaryDBCode] &&
            JSON.stringify(currentCard) !==
              JSON.stringify(cardsData[action.parentCardId])
          ) {
            const currentCardData = cardsData[action.referralId];
            Object.assign(currentCardData, cardData);
            currentCardData.errorData = {};
            const updateStore: any = {
              cardsData: cardsData,
            };
            yield put(setStore(updateStore));
          } else if (
            formData[0][action.referenceData.primaryDBCode] ||
            action.mode === 'Insert'
          ) {
            if (action.parentCardId) {
              let existingCardsData = yield select(getCard);
              const cardsData = cloneDeep(existingCardsData);
              if (
                cardsData[action.referralId].data.attachmentData !== undefined
              ) {
                let attachmentList: any = [];
                cardsData[action.referralId].data.attachmentData.map(
                  (attachments: any) => {
                    attachments.attachment[0]['ATTACHMENT_MODE'] = 'saved';
                    attachmentList.push(attachments);
                  }
                );

                const currentCardData = cardsData[action.referralId];
                currentCardData.buttonClicked = '';
                let updatedCard: any = { ...cardData };
                if (
                  action.referenceData.actionType &&
                  action.referenceData.actionType === 'UPSERT'
                ) {
                  updatedCard.errorData = {};
                }
                updatedCard.data = {
                  ...updatedCard.data,
                  attachmentData: attachmentList,
                };
                yield put(
                  setFormData(
                    updatedCard,
                    action.referralId,
                    action.resetForm,
                    false,
                    false
                  )
                );
              } else {
                let updatedCard: any = { ...cardData };
                if (
                  action.referenceData.actionType &&
                  action.referenceData.actionType === 'UPSERT'
                ) {
                  updatedCard.errorData = {};
                }
                yield put(
                  setFormData(
                    updatedCard,
                    action.referralId,
                    action.resetForm,
                    false,
                    false
                  )
                );
              }
            } else {
              if (!action.gridButtonAction) {
                yield router.navigate(action.location.pathname, {
                  state: updateRouterParam,
                  replace: true,
                });
                yield put(
                  setFormData(
                    cardData,
                    action.referralId,
                    action.resetForm,
                    false,
                    false
                  )
                );
              }
            }
          } else {
            const messages: string[] = [];
            messages.push('Data is corrupted. Please refresh the Grid');
            yield put(setToggleMessages(messages));
          }
        } else if (existingCardData[action.referralId]) {
          const cardData = new CardData(
            action.referralId,
            CardType.FORM,
            formData,
            action.portalId,
            action.parentId,
            rd,
            existingCardData[action.referralId].errorData,
            existingOptions,
            undefined,
            undefined,
            undefined,
            workflowActions
          );
          const previousData =
            existingCardData[action.parentId] &&
            existingCardData[action.parentId].referenceData
              ? existingCardData[action.parentId].referenceData
              : {};
          const portalData = new CardData(
            action.parentId,
            CardType.PORTAL,
            [],
            action.portalId,
            action.parentId,
            previousData,
            {},
            existingOptions
          );

          const updateRouterParam: any = {};
          Object.assign(updateRouterParam, action.location.state);
          Object.assign(
            previousData,
            action.location.state[action.parentId],
            neededData
          );
          updateRouterParam[action.parentId] = previousData;

          yield router.navigate(action.location.pathname, {
            state: updateRouterParam,
            replace: true,
          });
          yield all([
            put(setFormData(cardData, action.referralId, false, false, false)),
            put(setFormData(portalData, action.parentId, false, false, false)),
          ]);
        }
      }
    }
  } catch (e) {
    console.log('getFormDataSaga===========', e);
  }
}

function* getDynamicOptionChildFFList(
  existingCardData: any,
  formData: any,
  existingOptions: any,
  childForms: any
): Generator<any, any, any> {
  try {
    if (existingCardData && Object.keys(existingCardData.options).length > 0) {
      for (const option of Object.keys(existingCardData.options)) {
        for (const form of childForms) {
          if (formData[0].child.hasOwnProperty([form.configObjectId])) {
            for (const formsection of form.formSections) {
              for (const formfield of formsection.formFields) {
                let optionArray = option.split('_');
                let childFormData =
                  formData[0].child[form.configObjectId][optionArray[1]];
                if (
                  (formfield.type === 'SelectOption' ||
                    formfield.type === 'MultiSelect') &&
                  optionArray[2] === formfield.configObjectId
                ) {
                  if (childFormData) {
                    const needSelectCall = existingCardData.options[
                      option
                    ].filter((optionObj: any) => {
                      return (
                        optionObj.id ===
                        childFormData[formfield.logicalColumn.dbCode]
                      );
                    });
                    if (needSelectCall.length === 0) {
                      const optionsData = yield call(
                        base.getOptionsData,
                        formfield.configObjectId,
                        childFormData
                      );
                      existingOptions[option] = optionsData;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } catch (e) {
    console.log('Error occured in getDynamic Option ::::', e.message);
  }
}

function* getReportFormData(action: any): Generator<any, any, any> {
  try {
    const { data } = yield call(
      base.getConfigData,
      'ReportFormQuery',
      action.formId
    );

    let formData = {
      '0': {
        child: {},
      },
    };

    let defaultParentFormData = base.getDefaultDataMap(data.Form);
    Object.assign(formData[0], defaultParentFormData);

    data.Form.formSections.map((formSection: any) => {
      if (formSection.formFields)
        Object.assign(
          formData[0],
          base.getDefaultFFMap(formSection.formFields, formData[0])
        );
      return null;
    });

    const cardData = new CardData(
      action.referralId,
      CardType.FILTERFORM,
      formData,
      action.portalId,
      action.parentId
    );
    yield put(setFormData(cardData, action.referralId, false, false, false));
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}