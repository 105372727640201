import { getPrivilege } from '../../utils/ClientUtils';
import {
  isConfigObjectAccessible,
  isConfigObjectEditable,
} from '../../composite-entity/form/CardGenerators';
import { extractLabel } from '../../utils/intlutils';
import { columnExtracter, filterExtracter } from './ColumnTypeExtracter';

export const dataGridSelectOptionExtractor = (dataGridDetails: any) => {
  let dataGridColumns = dataGridDetails.dataGridColumns.filter(
    (dataGridColumn: any) =>
      !dataGridColumn.actionColumn ||
      (dataGridColumn.actionColumn && dataGridColumn.hyperLink)
  );
  let dataGridColumnDetails: any = {};
  let dataGridSelectOption: any = [];
  dataGridColumns.forEach(function (dataGridColumn: any, index: any) {
    if (
      dataGridColumn.formField &&
      dataGridDetails.form &&
      (dataGridColumn.formField.type === 'SelectOption' ||
        dataGridColumn.formField.type === 'MultiSelect') &&
      dataGridColumn.formField.selectItemsReferenceID
    ) {
      dataGridSelectOption.push(dataGridColumn.formField.configObjectId);
      dataGridColumnDetails['formId'] = dataGridDetails.form.configObjectId;
      dataGridColumnDetails['occuranceNumber'] = 0;
    } else if (dataGridColumn.selectItemsReferenceID) {
      dataGridSelectOption.push(dataGridColumn.configObjectId);
      dataGridColumnDetails['formId'] = dataGridDetails.configObjectId;
      dataGridColumnDetails['occuranceNumber'] = 0;
    }
  });
  dataGridColumnDetails['optionIds'] = dataGridSelectOption;
  return dataGridColumnDetails;
};

export const dataGridPropsExtractor = (
  dataGridDetails: any,
  userContext: any,
  styleName: any,
  ioTheme: any,
  ioMode: any,
  locale: any,
  handleClick: any,
  props: any,
  useErrorDataUsingDBCode: any,
  cardsData: any,
  processDropDownValues: any
) => {
  let result: any = {};
  let dataGridProps: any = {};
  dataGridProps['isAccessible'] = 'VIEW';
  dataGridProps['borderEnable'] = dataGridDetails.isBorderEnable;
  dataGridProps['strippedEnable'] = dataGridDetails.isStrippedEnable;
  dataGridProps['hoverEnable'] = dataGridDetails.isHoverEnable;
  dataGridProps['scrollEnable'] = dataGridDetails.isScrollEnable;
  dataGridProps['serverPaginationEnable'] =
    dataGridDetails.isServerPaginationEnable;
  dataGridProps['subComponentEnable'] = dataGridDetails.isSubComponentEnable;
  dataGridProps['subComponentType'] = dataGridDetails.subComponentType;
  dataGridProps['rowSelectionEnable'] = dataGridDetails.isRowSelectionEnable;
  dataGridProps['Title'] = extractLabel(dataGridDetails.name, locale);
  dataGridProps['gridDataAlignment'] = dataGridDetails.gridDataAlignment;
  dataGridProps['gridHeaderAlignment'] = dataGridDetails.gridHeaderAlignment;

  let columns: any = dataGridColumnsExtractor(
    dataGridDetails.dataGridColumns,
    userContext,
    styleName,
    ioTheme,
    ioMode,
    locale,
    handleClick,
    props,
    dataGridProps['subComponentEnable'],
    useErrorDataUsingDBCode,
    dataGridDetails.form,
    cardsData,
    processDropDownValues,
    dataGridDetails.configObjectId
  );
  let gridButtons: any = dataGridButtonsExtractor(dataGridDetails, userContext, props.referenceData);
  dataGridProps['columns'] = columns;
  dataGridProps['gridButtons'] = gridButtons;
  result['dataGridProps'] = dataGridProps;
  return result;
};

const sortListOfMap = (a: any, b: any) => {
  const genreA = a.order;
  const genreB = b.order;
  let comparison: number = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison;
};

function filterMultiSelectData(rows: any, id: any, filterValue: any) {
  let splitData = filterValue.split(',');
  return rows.filter((row: any) => {
    let temp: any = [];
    const rowValue = row.values[id];
    if (rowValue != null) {
      splitData.forEach(function (value: any, index: any) {
        temp.push(rowValue.includes(value));
      });
      let result = temp.reduce((sum: any, next: any) => sum || next);
      return result;
    } else {
      return false;
    }
  });
}

function filterDatePickerData(rows: any, id: any, filterValue: any) {
  return rows.filter((row: any) => {
    let convertedDates = new Date(filterValue);
    let fullDateDetails: any = convertedDates.toLocaleDateString();
    let rowDate = new Date(row.values[id]);
    if (fullDateDetails === rowDate.toLocaleDateString()) {
      return true;
    }
  });
}

function filterTimePickerData(rows: any, id: any, filterValue: any) {
  return rows.filter((row: any) => {
    let rowDate = new Date(row.values[id]);
    if (row.values[id] === filterValue) {
      return true;
    }
  });
}

function filterDateTimePickerData(rows: any, id: any, filterValue: any) {
  return rows.filter((row: any) => {
    let rowDate = new Date(row.values[id]);
    let filteredModifiedDateTime = filterValue;
    if (row.values[id].includes('T')) {
      let filterVal = filterValue && filterValue.split(' ');
      filteredModifiedDateTime = filterVal[0] + 'T' + filterVal[1];
    }
    if (row.values[id].includes(filteredModifiedDateTime)) {
      return true;
    }
  });
}

const dataGridColumnsExtractor = (
  allDataGridColumns: any,
  userContext: any,
  styleName: any,
  ioTheme: any,
  ioMode: any,
  locale: any,
  handleClick: any,
  props: any,
  subComponentEnable: any,
  useErrorDataUsingDBCode: any,
  form: any,
  cardsData: any,
  processDropDownValues: any,
  dataGridId: any
) => {
  let dataGridColumns = allDataGridColumns.filter(
    (dataGridColumn: any) =>
      !dataGridColumn.actionColumn ||
      (dataGridColumn.actionColumn && dataGridColumn.hyperLink)
  );

  let actionColumns = allDataGridColumns.filter(
    (dataGridColumn: any) =>
      dataGridColumn.actionColumn &&
      !dataGridColumn.hyperLink &&
      dataGridColumn.actionColumnType !== 'ExpandableComponent' &&
      dataGridColumn.group === 'Action'
  );

  let expandColumns = allDataGridColumns.filter(
    (dataGridColumn: any) =>
      dataGridColumn.actionColumn &&
      !dataGridColumn.hyperLink &&
      dataGridColumn.group !== 'Action'
  );
  let dataGridColumnsList: any = dataGridColumns.sort(sortListOfMap);
  let actionColumnList: any = actionColumns
    ? actionColumns.sort(sortListOfMap)
    : [];
  let expandColumnsList: any = expandColumns
    ? expandColumns.sort(sortListOfMap)
    : [];
  let groupNameList: any = [];
  let buttonList: any = [];
  let extractedDataGridColumns: any = [];
  let accessedGridCount: number = 0;
  let windowWidth: number = window.innerWidth > 1105 ? window.innerWidth : 1105;
  if (subComponentEnable) {
    let expandColumnMap: any = {};
    expandColumnMap['Header'] = '';
    expandColumnMap['id'] = 'expander';
    expandColumnMap['Cell'] = columnExtracter('ExpandableColumn');
    expandColumnMap['nestedComponentType'] = props.datagrid.nestedComponentType;
    expandColumnMap['isAccessible'] = true;
    expandColumnMap['subComponentType'] = props.datagrid.subComponentType;
    expandColumnMap['componentId'] =
      props.datagrid.subComponentType === 'Form'
        ? props.editFormId
        : props.datagrid.subComponentType === 'SubPortal'
          ? props.datagrid.subPortals
          : props.datagrid.dataGrid
            ? props.datagrid.dataGrid.configObjectId
            : '';
    expandColumnMap['primaryDBCode'] = props.primaryDBCode;
    expandColumnMap['width'] = expandColumnsList.length * 25.85;
    expandColumnMap['expandButtons'] = expandColumnsList;
    expandColumnMap['dataGridId'] = dataGridId;
    expandColumnMap['entityName'] = props.entityName;
    expandColumnMap['entityId'] = props.entityId;
    expandColumnMap['portalId'] = props.portalId;
    expandColumnMap['nodeId'] = props.nodeId;
    expandColumnMap['referralId'] = props.referralId;
    expandColumnMap['datagrid'] = props.datagrid;
    expandColumnMap['parentData'] = props.referenceData;
    expandColumnMap['location'] = props.location;
    expandColumnMap['parentProps'] = props;
    expandColumnMap['onClickHandler'] = handleClick;
    extractedDataGridColumns.push(expandColumnMap);
  }

  dataGridColumnsList.forEach(function (dataGridColumn: any, index: any) {
    let privilege: string = getPrivilege(
      dataGridColumn.privileges,
      userContext.getCurrentRole()
    );
    let isAccessibleRegex: boolean = isConfigObjectAccessible(
      dataGridColumn.accessibilityRegex,
      userContext.getCurrentRole()
    );
    let isAccessible: boolean = !dataGridColumn.visible
      ? false
      : privilege === 'NOPRIVILEGE'
        ? false
        : isAccessibleRegex
          ? true
          : false;
    if (isAccessible) {
      accessedGridCount++;
    }
  });
  let overallWidth = 80 / accessedGridCount > 10 ? 80 / accessedGridCount : 10;

  dataGridColumnsList.forEach(function (dataGridColumn: any, index: any) {
    let dataGridColumnDetails: any = {};

    let privilege: string = getPrivilege(
      dataGridColumn.privileges,
      userContext.getCurrentRole()
    );
    let isAccessible: boolean = isConfigObjectAccessible(
      dataGridColumn.accessibilityRegex,
      userContext.getUserDetail()
    );
    isAccessible = !dataGridColumn.visible
      ? false
      : privilege === 'NOPRIVILEGE'
        ? false
        : isAccessible
          ? true
          : false;
    if (dataGridColumn.isShowOnlyOnEdit) {
      isAccessible = props.editableGridEnable ? true : false;
    }
    dataGridColumnDetails['privilege'] = privilege;
    dataGridColumnDetails['editabilityRegex'] = dataGridColumn.editabilityRegex;
    dataGridColumnDetails['isAccessible'] = isAccessible;
    dataGridColumnDetails['configObjectId'] = dataGridColumn.configObjectId;
    dataGridColumnDetails['columnDataAlignment'] =
      dataGridColumn.columnDataAlignment;
    dataGridColumnDetails['columnHeaderAlignment'] =
      dataGridColumn.columnHeaderAlignment;

    dataGridColumnDetails['styleName'] = styleName;
    dataGridColumnDetails['ioMode'] = ioMode;
    dataGridColumnDetails['ioTheme'] = ioTheme;

    dataGridColumnDetails['accessor'] = dataGridColumn.formField
      ? dataGridColumn.formField.dbCode
        ? dataGridColumn.formField.dbCode
        : dataGridColumn.formField.logicalColumn.dbCode
      : dataGridColumn.logicalColumn
        ? dataGridColumn.logicalColumn.dbCode
        : dataGridColumn.dbCode
          ? dataGridColumn.dbCode
          : undefined;
    dataGridColumnDetails['primaryDBCode'] = props.primaryDBCode;
    dataGridColumnDetails['Header'] = extractLabel(
      dataGridColumn.headerName,
      locale
    );
    dataGridColumnDetails['parentProps'] = props;
    dataGridColumnDetails['useErrorDataUsingDBCode'] = useErrorDataUsingDBCode;
    dataGridColumnDetails['referralId'] = props.referralId;
    dataGridColumnDetails['Cell'] = columnExtracter('DefaultCell');

    if (dataGridColumn.dateFormat && !dataGridColumn.isTimeStamp) {
      dataGridColumnDetails['Cell'] = columnExtracter('DatePicker');
      dataGridColumnDetails['dateFormat'] = dataGridColumn.dateFormat;
      dataGridColumnDetails['isTimeStamp'] = dataGridColumn.isTimeStamp;
    } else if (dataGridColumn.dateFormat && dataGridColumn.isTimeStamp) {
      dataGridColumnDetails['Cell'] = columnExtracter('DateTimePicker');
      dataGridColumnDetails['dateFormat'] = dataGridColumn.dateFormat;
      dataGridColumnDetails['isTimeStamp'] = dataGridColumn.isTimeStamp;
    }

    if (dataGridColumn.hyperLink && dataGridColumn.actionColumn) {
      dataGridColumnDetails['Cell'] = columnExtracter('HyperLink');
      dataGridColumnDetails['onClickHandler'] = handleClick;
      dataGridColumnDetails['parentProps'] = props;
      dataGridColumnDetails['actionColumnType'] =
        dataGridColumn.actionColumnType;
      dataGridColumnDetails['hyperlinkDBCode'] = dataGridColumn.hyperlinkDBCode;
      dataGridColumnDetails['hrefValue'] = dataGridColumn.hrefValue;
      dataGridColumnDetails['modalCss'] = dataGridColumn.modalCss;
    }

    if (dataGridColumn.isAuditColumnJson) {
      dataGridColumnDetails['Cell'] = columnExtracter('AuditColumnJson');
      dataGridColumnDetails['auditColumnName'] = dataGridColumn.auditColumnName;
      dataGridColumnDetails['onClickHandler'] = handleClick;
      dataGridColumnDetails['parentProps'] = props;
      dataGridColumnDetails['actionColumnType'] =
        dataGridColumn.actionColumnType;
      dataGridColumnDetails['hyperlinkDBCode'] = dataGridColumn.hyperlinkDBCode;
      dataGridColumnDetails['hrefValue'] = dataGridColumn.hrefValue;
      dataGridColumnDetails['modalCss'] = dataGridColumn.modalCss;
    }

    dataGridColumnDetails['filterEnable'] = dataGridColumn.filterEnable;

    if (dataGridColumn.filterEnable) {
      dataGridColumnDetails['Filter'] = filterExtracter(
        dataGridColumn.filterType
      );

      switch (dataGridColumn.filterType) {
        case 'SelectOption':
          dataGridColumnDetails['filter'] = 'equals';
          break;
        case 'MultiSelect':
          dataGridColumnDetails['filter'] = filterMultiSelectData;
          break;
        case 'DatePicker':
          dataGridColumnDetails['filter'] = filterDatePickerData;
          break;
        case 'TimePicker':
          dataGridColumnDetails['filter'] = filterTimePickerData;
          break;
        case 'DateTimePicker':
          dataGridColumnDetails['filter'] = filterDateTimePickerData;
          break;

        default:
      }
    }
    dataGridColumnDetails['isEllipsesEnable'] = dataGridColumn.isEllipsesEnable;
    if (
      dataGridColumn.multivalueList ||
      dataGridColumn.selectItemsReferenceID
    ) {
      dataGridColumnDetails['configProp'] = dataGridColumn;
      dataGridColumnDetails['formId'] = dataGridId;
      dataGridColumnDetails['occuranceNumber'] = 0;
      dataGridColumnDetails['editEnable'] = false;
      dataGridColumnDetails['cardsData'] = cardsData;
      dataGridColumnDetails['processDropDownValues'] = processDropDownValues;

      switch (dataGridColumn.columnType) {
        case 'SelectOption':
          dataGridColumnDetails['Cell'] = columnExtracter('SelectOption');
          break;
        case 'MultiSelect':
          dataGridColumnDetails['Cell'] = columnExtracter('MultiSelect');
          break;
        default:
          dataGridColumnDetails['Cell'] = columnExtracter('SelectOption');
      }
    }

    if (dataGridColumn.formField && form) {
      let formPrivilege: string = getPrivilege(
        form.privileges,
        userContext.getCurrentRole()
      );
      let formIsAccessible: boolean = isConfigObjectAccessible(
        form.accessibilityRegex,
        userContext.getCurrentRole()
      );
      let formIsEditibale: boolean = isConfigObjectEditable(
        formIsAccessible,
        formPrivilege,
        form.editabilityRegex,
        userContext.getCurrentRole(),
        'EDIT',
        true
      );

      let ffPrivilege: string = getPrivilege(
        dataGridColumn.formField.privileges,
        userContext.getCurrentRole()
      );
      let ffIsAccessible: boolean = isConfigObjectAccessible(
        dataGridColumn.formField.accessibilityRegex,
        userContext.getCurrentRole()
      );
      let ffIsEditibale: boolean = isConfigObjectEditable(
        ffIsAccessible,
        ffPrivilege,
        dataGridColumn.formField.editabilityRegex,
        userContext.getCurrentRole(),
        formPrivilege,
        formIsEditibale
      );
      dataGridColumnDetails['Cell'] = columnExtracter('MobileViewCell');
      dataGridColumnDetails['isDefaultEditable'] =
        dataGridColumn.isDefaultEditable;
      if (
        (ffPrivilege === 'EDIT' || dataGridColumn.formField.isHyperlink) &&
        true &&
        true
      ) {
        dataGridColumnDetails['editEnable'] =
          privilege === 'EDIT'
            ? props.datagrid.isEditButtonEnable
              ? props.editableGridEnable
                ? true
                : false
              : true
            : false;
        dataGridColumnDetails['Cell'] = columnExtracter(
          dataGridColumn.formField.type == 'SelectOption' &&
            dataGridColumn.headerName === 'Country'
            ? 'FlagsSelectOption'
            : dataGridColumn.formField.type
        );
        switch (dataGridColumn.formField.type) {
          case 'SelectOption':
            {
              dataGridColumnDetails['configProp'] = dataGridColumn.formField;
              dataGridColumnDetails['formId'] = form.configObjectId;
              dataGridColumnDetails['cardsData'] = cardsData;
              dataGridColumnDetails['gridType'] = props.gridType;
              dataGridColumnDetails['routerParam'] = props.location.state;
              dataGridColumnDetails['occuranceNumber'] = 0;
              dataGridColumnDetails['processDropDownValues'] =
                processDropDownValues;
            }
            break;

          case 'MultiSelect':
            {
              dataGridColumnDetails['configProp'] = dataGridColumn.formField;
              dataGridColumnDetails['formId'] = form.configObjectId;
              dataGridColumnDetails['cardsData'] = cardsData;
              dataGridColumnDetails['gridType'] = props.gridType;
              dataGridColumnDetails['routerParam'] = props.location.state;
              dataGridColumnDetails['occuranceNumber'] = 0;
              dataGridColumnDetails['processDropDownValues'] =
                processDropDownValues;
            }
            break;

          case 'DateTimePicker':
            {
              dataGridColumnDetails['configProp'] = dataGridColumn.formField;
              dataGridColumnDetails['formId'] = form.configObjectId;
            }
            break;
        }
      }
    }

    if (isAccessible) {
      let width: number =
        (windowWidth *
          (dataGridColumn.width ? dataGridColumn.width : overallWidth)) /
        100;
      dataGridColumnDetails['width'] = width;
      dataGridColumnDetails['changeEnable'] =
        window.innerWidth > 750 ? true : false;
    }
    extractedDataGridColumns.push(dataGridColumnDetails);
  });

  actionColumnList.forEach(function (actionColumn: any, index: any) {
    if (actionColumn.group && !groupNameList.includes(actionColumn.group)) {
      groupNameList.push(actionColumn.group);
    }
    let dataGridColumnDetails: any = {};

    let privilege: string = getPrivilege(
      actionColumn.privileges,
      userContext.getCurrentRole()
    );
    let isAccessible = true;
    dataGridColumnDetails['isAccessible'] = !actionColumnList[0].visible
      ? false
      : privilege === 'NOPRIVILEGE'
        ? false
        : isAccessible
          ? true
          : false;
    dataGridColumnDetails['HeaderName'] = actionColumn.headerName;
    dataGridColumnDetails['icon'] = actionColumn.icon;
    dataGridColumnDetails['id'] = actionColumn.configObjectId;
    dataGridColumnDetails['groupName'] = actionColumn.group;
    dataGridColumnDetails['width'] = actionColumn.width;
    dataGridColumnDetails['actionColumnType'] = actionColumn.actionColumnType;
    dataGridColumnDetails['hyperlinkDBCode'] = actionColumn.hyperlinkDBCode;
    dataGridColumnDetails['hrefValue'] = actionColumn.hrefValue;
    dataGridColumnDetails['dbCode'] = actionColumn.dbCode;
    dataGridColumnDetails['toolTip'] = actionColumn.toolTip;
    dataGridColumnDetails['accessibilityRegex'] =
      actionColumn.accessibilityRegex;
    dataGridColumnDetails['configObjectId'] = actionColumn.configObjectId;
    dataGridColumnDetails['modalCss'] = actionColumn.modalCss;
    buttonList.push(dataGridColumnDetails);
  });
  groupNameList.forEach(function (group: any, index: any) {
    let dataGridColumnDetails: any = {};
    dataGridColumnDetails['isAccessible'] = true;
    dataGridColumnDetails['styleName'] = styleName;
    dataGridColumnDetails['ioMode'] = ioMode;
    dataGridColumnDetails['ioTheme'] = ioTheme;
    dataGridColumnDetails['Header'] = extractLabel(group, locale);
    dataGridColumnDetails['Cell'] = columnExtracter('ActionButton');
    let buttonListColumns: any = [];
    buttonList.forEach(function (button: any, index: any) {
      if (group === button.groupName) {
        let buttonMap: any = {};
        buttonMap['HeaderName'] = extractLabel(button.HeaderName, locale);
        buttonMap['icon'] = button.icon;
        buttonMap['id'] = button.id;
        buttonMap['isAccessible'] = button.isAccessible;
        buttonMap['onClickHandler'] = handleClick;
        buttonMap['parentProps'] = props;
        buttonMap['actionColumnType'] = button.actionColumnType;
        buttonMap['hyperlinkDBCode'] = button.hyperlinkDBCode;
        buttonMap['hrefValue'] = button.hrefValue;
        buttonMap['width'] = button.width;
        buttonMap['dbCode'] = button.dbCode;
        buttonMap['toolTip'] = button.toolTip;
        buttonMap['accessibilityRegex'] = button.accessibilityRegex;
        buttonMap['expressionAvailable'] = button.expressionAvailable;
        buttonMap['configObjectId'] = button.configObjectId;
        buttonMap['modalCss'] = button.modalCss;
        if (button.isAccessible) {
          buttonListColumns.push(buttonMap);
        }
      }
    });
    if (buttonListColumns.length > 0) {
      let columnWith = buttonListColumns[0]['width']
        ? subComponentEnable
          ? buttonListColumns[0]['width'] - 5
          : buttonListColumns[0]['width']
        : subComponentEnable
          ? 10
          : 15;
      let width: number = (windowWidth * columnWith) / 100;
      dataGridColumnDetails['width'] = width;
      dataGridColumnDetails['buttons'] = buttonListColumns;
      extractedDataGridColumns.push(dataGridColumnDetails);
    }
  });
  return extractedDataGridColumns;
};

const dataGridButtonsExtractor = (dataGrid: any, userContext: any,referenceData:any) => {
  let gridButtons: any = [];
  let button_Map: any = {};
  button_Map.configObjectId = 'filterbutton';
  button_Map.name = '';
  button_Map.icon = 'fa fa-filter';
  button_Map.buttonClass = 'gridFilterActionButton';
  button_Map.style = getCardStyle('padding-left:0px;padding-right:10px');
  button_Map.toolTip = 'Enable Filter';
  button_Map.dbCode = 'expander';
  gridButtons.push(button_Map);
  if (
    dataGrid.buttonPanels &&
    dataGrid.buttonPanels[0] &&
    dataGrid.buttonPanels[0].buttons.length > 0
  ) {
    const buttonPanelPrivilege = getPrivilege(
      dataGrid.buttonPanels[0].privileges,
      userContext.getCurrentRole()
    );
    if (buttonPanelPrivilege !== 'NOPRIVILEGE') {
      let buttonList = dataGrid.buttonPanels[0].buttons;
      const sortedButtonList = [...buttonList].sort((a: any, b: any) =>
        a.order > b.order ? 1 : -1
      );
      sortedButtonList.map((button: any) => {
        let isAccessible = true;
        let isAccessibleRegex: boolean = isConfigObjectAccessible(
          button.accessibilityRegex,
          referenceData
        );
        isAccessible =
          getPrivilege(button.privileges, userContext.getCurrentRole()) !==
          'NOPRIVILEGE'
            ? true
            : false;
        if (button.modalRequired && isAccessible && isAccessibleRegex) {
          let buttonMap: any = {};
          buttonMap.modalCss = button.modalCss;
          buttonMap.configObjectId = button.configObjectId;
          buttonMap.name = button.label;
          buttonMap.icon = button.icon;
          buttonMap.className = button.className;
          buttonMap.buttonClass = button.buttonClass;
          buttonMap.portalId = button.portalId;
          buttonMap.modalRequired = button.modalRequired;
          buttonMap.style = getCardStyle(button.buttonStyle);
          buttonMap.toolTip = button.toolTip;
          buttonMap.dbCode = button.dbCode;
          gridButtons.push(buttonMap);
        }
      });
    }
  }
  return gridButtons;
};

const getCardStyle = (cardStyle: string) => {
  const cardStyleList = cardStyle ? cardStyle.split(';') : [];
  let cardStyleMap: any = {};

  if (cardStyleList) {
    cardStyleList.forEach(function (elem: any) {
      const styleMap = elem.split(':');

      if (styleMap && styleMap.length === 2) {
        cardStyleMap[styleMap[0].trim()] = styleMap[1].trim();
      }
    });
  }
  return cardStyleMap;
};
